import { map, switchMap, tap } from "rxjs/operators";
import { from } from "rxjs";
import { ofType } from "redux-observable";
import API from "../api";
import { fetchUser } from "../UserDetails/effects";

export const EDIT_USER = "EDIT_USER";
export const EDIT_USER_SUCCESSFUL = "EDIT_USER_SUCCESSFUL";

const updateUser = ({ id, user }) =>
  API.post(`users/${id}`, { json: user }).then(() => editUserSuccessful());

export const editUser = (id, user) => ({
  type: EDIT_USER,
  payload: {
    id,
    user,
  },
});

export const editUserSuccessful = () => ({
  type: EDIT_USER_SUCCESSFUL,
});

export const editUserEpic = (action$) =>
  action$.pipe(
    ofType(EDIT_USER),
    switchMap((action: any) => from(updateUser(action.payload)))
  );

export const refreshUserEpic = (action$, $state) =>
  action$.pipe(
    ofType(EDIT_USER_SUCCESSFUL),
    map((action) => fetchUser($state.value.currentUser.id))
  );
