import * as React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/img/A1.png";
import { UncontrolledTooltip } from "reactstrap";

export const SiteNav = () => (
  <nav
    className="navbar navbar-vertical navbar-vertical-sm fixed-left navbar-expand-md navbar-light"
    id="sidebarSmall"
  >
    <div className="container-fluid">
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#sidebarSmallCollapse"
        aria-controls="sidebarSmallCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <a className="navbar-brand" href="./">
        <img
          src={logo}
          className="navbar-brand-img mx-auto"
          alt="..."
          height="40px"
        />
      </a>

      <div className="collapse navbar-collapse" id="sidebarSmallCollapse">
        <form className="mt-4 mb-3 d-md-none">
          <div className="input-group input-group-rounded input-group-merge">
            <input
              type="search"
              className="form-control form-control-rounded form-control-prepended"
              placeholder="Search"
              aria-label="Search"
            />
            <div className="input-group-prepend">
              <div className="input-group-text">
                <span className="fe fe-search"></span>
              </div>
            </div>
          </div>
        </form>

        <hr className="navbar-divider d-none d-md-block mt-0 mb-3" />

        <ul className="navbar-nav">
          <li className="nav-item" id="dashboardLink">
            <Link to="/" className="nav-link">
              <i className="fe fe-bar-chart-2"></i>
              <span className="d-md-none">Dashboard</span>
            </Link>
          </li>
          <UncontrolledTooltip placement="right" target="dashboardLink">
            Dashboard
          </UncontrolledTooltip>
          {/* <li className="nav-item" id="customerLink">
            <Link to="/customers" className="nav-link">
              <i className="fe fe-briefcase"></i>
              <span className="d-md-none">Customers</span>
            </Link>
          </li>
          <UncontrolledTooltip placement="right" target="customerLink">
            Customers
          </UncontrolledTooltip> */}
          <li className="nav-item" id="locationLink">
            <Link to="/locations" className="nav-link">
              <i className="fe fe-map-pin"></i>
              <span className="d-md-none">Locations</span>
            </Link>
          </li>
          <UncontrolledTooltip placement="right" target="locationLink">
            Locations
          </UncontrolledTooltip>
          <li className="nav-item" id="containerLink">
            <Link to="/containers" className="nav-link">
              <i className="fe fe-database"></i>
              <span className="d-md-none">Containers</span>
            </Link>
          </li>
          <UncontrolledTooltip placement="right" target="containerLink">
            Containers
          </UncontrolledTooltip>
          <li className="nav-item" id="userLink">
            <Link to="/users" className="nav-link">
              <i className="fe fe-users"></i>
              <span className="d-md-none">Users</span>
            </Link>
          </li>
          <UncontrolledTooltip placement="right" target="userLink">
            Users
          </UncontrolledTooltip>
          <li className="nav-item" id="alertLink">
            <Link to="/alerts" className="nav-link">
              <i className="fe fe-bell"></i>
              <span className="d-md-none">Alerts</span>
            </Link>
          </li>
          <UncontrolledTooltip placement="right" target="alertLink">
            Alerts
          </UncontrolledTooltip>
          <li className="nav-item" id="chemicalLink">
            <Link to="/chemicals" className="nav-link">
              <i className="fe fe-droplet"></i>
              <span className="d-md-none">Chemicals</span>
            </Link>
          </li>
          <UncontrolledTooltip placement="right" target="chemicalLink">
            Chemicals
          </UncontrolledTooltip>
          <li className="nav-item" id="sizeLink">
            <Link to="/sizes" className="nav-link">
              <i className="fe fe-box"></i>
              <span className="d-md-none">Container Sizes</span>
            </Link>
          </li>
          <UncontrolledTooltip placement="right" target="sizeLink">
            Container Sizes
          </UncontrolledTooltip>
          <li className="nav-item" id="settingsLink">
            <Link to="/settings" className="nav-link">
              <i className="fe fe-sliders"></i>
              <span className="d-md-none">Settings</span>
            </Link>
          </li>
          <UncontrolledTooltip placement="right" target="settingsLink">
            Settings
          </UncontrolledTooltip>
        </ul>

        <hr className="navbar-divider my-3" />

        <div className="mt-auto"></div>

        <div
          className="navbar-user d-none d-md-flex flex-column"
          id="sidebarSmallUser"
        >
          {/* <Link to="/search" className="navbar-user-link mb-3">
            <span className="icon">
              <i className="fe fe-search"></i>
            </span>
          </Link> */}
          <ul className="navbar-nav">
            <li className="nav-item" id="logoutLink">
              <a className="nav-link" onClick={() => {}}>
                <i className="fe fe-log-out"></i>
                <span className="d-md-none">Logout</span>
              </a>
            </li>
            <UncontrolledTooltip placement="right" target="logoutLink">
              Logout
            </UncontrolledTooltip>
          </ul>

          <div className="dropdown-menu" aria-labelledby="sidebarSmallIconCopy">
            <a href="./profile-posts.html" className="dropdown-item">
              Profile
            </a>
            <a href="./settings.html" className="dropdown-item">
              Settings
            </a>
            <hr className="dropdown-divider" />
            <a href="./sign-in.html" className="dropdown-item">
              Logout
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
);
