import { map, switchMap, tap } from "rxjs/operators";
import { from } from "rxjs";
import { ofType } from "redux-observable";
import API from "../api";
import { fetchUsers } from "../UserList/effects";

export const CREATE_USER = "CREATE_USER";
export const CREATE_USER_SUCCESSFUL = "CREATE_USER_SUCCESSFUL";

const addUser = (user) =>
  API.post("users", { json: user })
    .json<any>()
    .then((users = []) => createUserSuccessful());

export const createUser = (user) => ({
  type: CREATE_USER,
  payload: user,
});

export const createUserSuccessful = () => ({
  type: CREATE_USER_SUCCESSFUL,
});

export const createUserEpic = (action$) =>
  action$.pipe(
    ofType(CREATE_USER),
    switchMap((action: any) => from(addUser(action.payload)))
  );

export const refreshUsersEpic = (action$) =>
  action$.pipe(
    ofType(CREATE_USER_SUCCESSFUL),
    map((action) => fetchUsers())
  );
