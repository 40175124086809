import { map, switchMap, tap } from "rxjs/operators";
import { from } from "rxjs";
import { ofType } from "redux-observable";
import { push } from "connected-react-router";
import API from "../api";

export const FETCH_LOCATIONS = "FETCH_LOCATIONS";
export const FETCH_LOCATIONS_SUCCESSFUL = "FETCH_LOCATIONS_SUCCESSFUL";

const getLocations = (options) =>
  API.get("locations", {
    searchParams: options,
  })
    .json<any>()
    .then((locations = []) => fetchLocationsSuccessful(locations));

export const fetchLocations = (options) => ({
  type: FETCH_LOCATIONS,
  payload: options,
});

export const fetchLocationsSuccessful = (locations: any) => ({
  type: FETCH_LOCATIONS_SUCCESSFUL,
  payload: locations,
});

export const fetchLocationsEpic = (action$) =>
  action$.pipe(
    ofType(FETCH_LOCATIONS),
    switchMap((action: any) => from(getLocations(action.payload)))
  );

export const locationsReducer = (
  state = { rows: [], count: 0, loading: true },
  action: any
) => {
  switch (action.type) {
    case FETCH_LOCATIONS:
      return {
        ...state,
        rows: [],
        count: 0,
        loading: true,
      };
    case FETCH_LOCATIONS_SUCCESSFUL:
      return {
        ...action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
