import { map, switchMap, tap } from "rxjs/operators";
import { from } from "rxjs";
import { ofType } from "redux-observable";
import API from "../api";
import { fetchLocation } from "../LocationDetails/effects";

export const EDIT_LOCATION = "EDIT_LOCATION";
export const EDIT_LOCATION_SUCCESSFUL = "EDIT_LOCATION_SUCCESSFUL";

const updateLocation = ({ id, location }) =>
  API.post(`locations/${id}`, { json: location }).then(() =>
    editLocationSuccessful()
  );

export const editLocation = (id, location) => ({
  type: EDIT_LOCATION,
  payload: {
    id,
    location,
  },
});

export const editLocationSuccessful = () => ({
  type: EDIT_LOCATION_SUCCESSFUL,
});

export const editLocationEpic = (action$) =>
  action$.pipe(
    ofType(EDIT_LOCATION),
    switchMap((action: any) => from(updateLocation(action.payload)))
  );

export const refreshLocationEpic = (action$, $state) =>
  action$.pipe(
    ofType(EDIT_LOCATION_SUCCESSFUL),
    map((action) => fetchLocation($state.value.currentLocation.id))
  );
