import * as React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  InputGroup,
  Spinner,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import "./Loading.css";
import { Routes } from "../routes";
import { checkAuth } from "../Auth/effects";

export const Loading = () => {
  const isAuthed = useSelector<any>((state) => state.auth.authenticated);

  const dispatch = useDispatch();

  const checkAuthFn = React.useCallback(() => dispatch(checkAuth()), [
    dispatch,
  ]);

  React.useEffect(() => {
    if (isAuthed == null) checkAuthFn();
  });

  return isAuthed == null ? (
    <div className="minh-100 d-flex align-items-center bg-auth">
      <Container>
        <Row className="justify-content-center">
          <Spinner size="lg" color="primary" /> {isAuthed}
        </Row>
      </Container>
    </div>
  ) : (
    <Routes />
  );
};
