import { map, switchMap, tap } from "rxjs/operators";
import { from } from "rxjs";
import { ofType } from "redux-observable";
import API from "../api";
import { fetchContainerSizes } from "../ContainerSizeList/effects";

export const CREATE_CONTAINER_SIZE = "CREATE_CONTAINER_SIZE";
export const CREATE_CONTAINER_SIZE_SUCCESSFUL =
  "CREATE_CONTAINER_SIZE_SUCCESSFUL";

const addContainerSize = (containerSize) =>
  API.post("container-sizes", { json: containerSize })
    .json<any>()
    .then((containerSizes = []) => createContainerSizeSuccessful());

export const createContainerSize = (containerSize) => ({
  type: CREATE_CONTAINER_SIZE,
  payload: containerSize,
});

export const createContainerSizeSuccessful = () => ({
  type: CREATE_CONTAINER_SIZE_SUCCESSFUL,
});

export const createContainerSizeEpic = (action$) =>
  action$.pipe(
    ofType(CREATE_CONTAINER_SIZE),
    switchMap((action: any) => from(addContainerSize(action.payload)))
  );

export const refreshContainerSizesEpic = (action$) =>
  action$.pipe(
    ofType(CREATE_CONTAINER_SIZE_SUCCESSFUL),
    map((action) => fetchContainerSizes())
  );
