import React from "react";
import {
  VictoryThemeDefinition,
  VictoryChart,
  VictoryArea,
  VictoryAxis,
  VictoryScatter,
  VictoryTooltip,
} from "victory";
import { format } from "date-fns";

const colors = [
  "#252525",
  "#525252",
  "#737373",
  "#969696",
  "#bdbdbd",
  "#d9d9d9",
  "#f0f0f0",
];
const charcoal = "#252525";
const grey = "#969696";

// Typography
const letterSpacing = "normal";
const fontSize = 10;

// Layout
const baseProps = {
  padding: 0,
  colorScale: colors,
};

// Labels
const baseLabelStyles = {
  font: '"Cerebri Sans", sans-serif',
  fontSize,
  letterSpacing,
  padding: 2,
  fill: charcoal,
  stroke: "transparent",
};

const centeredLabelStyles = { ...baseLabelStyles, textAnchor: "middle" };

// Strokes
const strokeDasharray = "6, 5";
const strokeLinecap = "round";
const strokeLinejoin = "round";

// Put it all together...
const areaTheme: VictoryThemeDefinition = {
  area: {
    ...baseProps,
    style: {
      data: {
        fill: "#41C773",
      },
      labels: baseLabelStyles,
    },
  },

  axis: {
    style: {
      axis: {
        fill: "transparent",
        stroke: charcoal,
        strokeWidth: 1,
        strokeLinecap,
        strokeLinejoin,
      },
      axisLabel: { ...centeredLabelStyles, padding: 0 } as React.CSSProperties,
      grid: {
        fill: "none",
        stroke: "#EFEFEF",
        strokeDasharray,
        strokeLinecap,
        strokeLinejoin,
        pointerEvents: "painted",
      },
      ticks: {
        fill: "transparent",
        size: 1,
        stroke: "transparent",
      },
      tickLabels: baseLabelStyles,
    },
    ...baseProps,
  },
  tooltip: {
    style: { padding: 0, pointerEvents: "none", ...baseLabelStyles },
    flyoutStyle: {
      stroke: charcoal,
      strokeWidth: 0.5,
      fill: "#fff",
      pointerEvents: "none",
    },
    flyoutPadding: 2,
    cornerRadius: 0,
    pointerLength: 5,
  },
};

const TotalWashesChart = ({ washes }) => (
  <VictoryChart
    theme={{
      ...areaTheme,
    }}
    height={170}
    scale={{ x: "time" }}
    padding={25}
  >
    <VictoryArea data={washes} key="id" x="timestamp" y="count" />

    <VictoryAxis
      dependentAxis
      standalone={false}
      tickValues={[0, 1000, 2000]}
      tickFormat={(y) => y}
    />
    <VictoryAxis
      standalone={false}
      // tickValues={new Array(14).map((x, index) => addDays(new Date(), -index))}
      tickFormat={(x) => format(x, "dd/MM")}
    />
    <VictoryScatter
      x="timestamp"
      y="count"
      size={1}
      data={washes}
      labels={({ datum }) =>
        `Count: ${datum.count} \n ${format(
          datum.timestamp,
          "MM/dd/yy HH:mm aa"
        )}`
      }
      labelComponent={<VictoryTooltip />}
    />
  </VictoryChart>
);

export const TotalWashes = ({ total }) => (
  <div className="card">
    <div className="card-body">
      <div className="row align-items-center">
        <div className="col">
          <h6 className="text-uppercase text-muted mb-2">Total washes</h6>
          <span className="h2 mb-0">{total}</span>
        </div>
        <div className="col-auto">
          <span className="h2 fe fe-briefcase text-muted mb-0"></span>
        </div>
        <TotalWashesChart
          washes={[
            { count: 127, timestamp: new Date("2021-06-07T15:21:48Z") },
            { count: 342, timestamp: new Date("2021-06-08T15:23:48Z") },
            { count: 437, timestamp: new Date("2021-06-10T15:27:48Z") },
          ]}
        />
      </div>
    </div>
  </div>
);
