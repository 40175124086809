import * as React from "react";
import {
  Modal,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Col,
  Input,
  Row,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { editUser } from "./effects";

export const UserEdit = (props) => {
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const user: any = useSelector<any>((state) => state.currentUser);

  const dispatch = useDispatch();

  const addUser = React.useCallback(() => {
    props.toggle();
    return dispatch(
      editUser(user.id, {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
      })
    );
  }, [dispatch, firstName, lastName, email, password]);

  React.useEffect(() => {
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setEmail(user.email);
  }, [user]);

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle}>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          addUser();
        }}
      >
        <Card className="modal-card">
          <CardHeader>
            <h4 className="card-header-title">Edit User</h4>
            <Button close className="float-right" onClick={props.toggle} />
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="firstName">First Name</Label>
                  <Input
                    type="text"
                    name="firstName"
                    id="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="lastName">Last Name</Label>
                  <Input
                    type="text"
                    name="lastName"
                    id="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
          </CardBody>
          <CardFooter>
            <Button
              color="success"
              className="float-right ml-2"
              onClick={addUser}
            >
              Update
            </Button>
            <Button outline className="float-right" onClick={props.toggle}>
              Cancel
            </Button>
          </CardFooter>
        </Card>
      </Form>
    </Modal>
  );
};
