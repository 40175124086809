import * as React from "react";
import { DefaultLayout } from "../_components/DefaultLayout";
import { Header } from "../_components/Header";
import {
  Progress,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  CardHeader,
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Col,
  Input,
} from "reactstrap";
import { UserEdit } from "../UserEdit/UserEdit";
import { fetchUser } from "./effects";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { useParams } from "react-router-dom";

const UserDetailsView = () => {
  const [modal, setModal] = React.useState(false);
  let { id } = useParams();

  const user: any = useSelector<any>((state) => state.currentUser);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchUser(id));
  }, [id]);

  const toggle = () => setModal(!modal);

  return (
    <DefaultLayout>
      {" "}
      <div className="col-12">
        <Header
          title={`${user?.firstName ?? ""} ${user?.lastName ?? ""}`}
          pretitle="User"
        >
          <Button className="lift" onClick={toggle}>
            Edit User
          </Button>
        </Header>
        <div className="card">
          <div className="card-body">
            <div className="list-group list-group-flush my-n3">
              <div className="list-group-item">
                <div className="row align-items-center">
                  <div className="col">
                    <h5 className="mb-0">Name</h5>
                  </div>
                  <div className="col-auto">
                    {user?.firstName} {user?.lastName}
                  </div>
                </div>
              </div>
              <div className="list-group-item">
                <div className="row align-items-center">
                  <div className="col">
                    <h5 className="mb-0">Email</h5>
                  </div>
                  <div className="col-auto">{user?.email}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <UserEdit isOpen={modal} toggle={toggle} />
      </div>
    </DefaultLayout>
  );
};

export const UserDetails = () => <UserDetailsView />;
