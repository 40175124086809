import { map, switchMap, tap } from "rxjs/operators";
import { from } from "rxjs";
import { ofType } from "redux-observable";
import API from "../api";

export const FETCH_CHEMICAL = "FETCH_CHEMICAL";
export const FETCH_CHEMICAL_SUCCESSFUL = "FETCH_CHEMICAL_SUCCESSFUL";

export const FETCH_CHEMICAL_CONTAINERS = "FETCH_CHEMICAL_CONTAINERS";
export const FETCH_CHEMICAL_CONTAINERS_SUCCESSFUL =
  "FETCH_CHEMICAL_CONTAINERS_SUCCESSFUL";

const getChemical = (id) =>
  API.get(`chemicals/${id}`)
    .json<any>()
    .then((chemical = {}) => fetchChemicalSuccessful(chemical));

const getChemicalContainers = (id) =>
  API.get(`chemicals/${id}/containers`)
    .json<any>()
    .then((containers = {}) => fetchChemicalContainersSuccessful(containers));

export const fetchChemical = (id) => ({
  type: FETCH_CHEMICAL,
  payload: id,
});

export const fetchChemicalSuccessful = (chemical: any) => ({
  type: FETCH_CHEMICAL_SUCCESSFUL,
  payload: chemical,
});

export const fetchChemicalContainers = (id) => ({
  type: FETCH_CHEMICAL_CONTAINERS,
  payload: id,
});

export const fetchChemicalContainersSuccessful = (chemical: any) => ({
  type: FETCH_CHEMICAL_CONTAINERS_SUCCESSFUL,
  payload: chemical,
});

export const fetchChemicalEpic = (action$) =>
  action$.pipe(
    ofType(FETCH_CHEMICAL),
    switchMap((action: any) => from(getChemical(action.payload)))
  );

export const fetchChemicalContainersEpic = (action$) =>
  action$.pipe(
    ofType(FETCH_CHEMICAL_CONTAINERS),
    switchMap((action: any) => from(getChemicalContainers(action.payload)))
  );

export const chemicalReducer = (state = {}, action: any) => {
  switch (action.type) {
    case FETCH_CHEMICAL_SUCCESSFUL:
      return action.payload;
    default:
      return state;
  }
};

export const chemicalContainersReducer = (
  state = { rows: [] },
  action: any
) => {
  switch (action.type) {
    case FETCH_CHEMICAL_CONTAINERS_SUCCESSFUL:
      return action.payload;
    default:
      return state;
  }
};
