import * as React from "react";
import {
  Modal,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Col,
  Input,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { editCustomer } from "./effects";

export const CustomerEdit = (props) => {
  const [name, setName] = React.useState("");
  const customer: any = useSelector<any>((state) => state.currentCustomer);
  const dispatch = useDispatch();

  const updateCustomer = React.useCallback(() => {
    props.toggle();
    return dispatch(
      editCustomer(customer.id, {
        name: name,
      })
    );
  }, [dispatch, name, customer]);

  React.useEffect(() => {
    setName(customer.name);
  }, [customer]);

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle}>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          updateCustomer();
        }}
      >
        <Card className="modal-card">
          <CardHeader>
            <h4 className="card-header-title">Edit Customer</h4>
            <Button close className="float-right" onClick={props.toggle} />
          </CardHeader>
          <CardBody>
            <FormGroup row>
              <Label for="exampleSelect" sm={2}>
                Name
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="name"
                  id="customerName"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Col>
            </FormGroup>
          </CardBody>
          <CardFooter>
            <Button
              color="success"
              className="float-right ml-2"
              onClick={updateCustomer}
            >
              Update
            </Button>
            <Button outline className="float-right" onClick={props.toggle}>
              Cancel
            </Button>
          </CardFooter>
        </Card>
      </Form>
    </Modal>
  );
};
