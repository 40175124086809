import { combineEpics } from "redux-observable";
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import {
  loginEpic,
  checkAuthEpic,
  noAuthEpic,
  authEpic,
  authReducer,
} from "./Auth/effects";
import { chemicalsReducer, fetchChemicalsEpic } from "./ChemicalList/effects";
import {
  createChemicalEpic,
  refreshChemicalsEpic,
} from "./ChemicalCreate/effects";
import {
  containerSizesReducer,
  fetchContainerSizesEpic,
} from "./ContainerSizeList/effects";
import {
  createContainerSizeEpic,
  refreshContainerSizesEpic,
} from "./ContainerSizeCreate/effects";
import { customersReducer, fetchCustomersEpic } from "./CustomerList/effects";
import {
  refreshCustomersEpic,
  createCustomerEpic,
} from "./CustomerCreate/effects";
import { fetchLocationsEpic, locationsReducer } from "./LocationList/effects";
import {
  createLocationEpic,
  refreshLocationsEpic,
} from "./LocationCreate/effects";
import { usersReducer, fetchUsersEpic } from "./UserList/effects";
import { createUserEpic, refreshUsersEpic } from "./UserCreate/effects";
import {
  createContainerEpic,
  refreshContainersEpic,
} from "./ContainerCreate/effects";
import {
  fetchContainersEpic,
  containersReducer,
} from "./ContainerList/effects";
import {
  containerReducer,
  purchaseEpic,
  fetchContainerEpic,
  fetchContainerLevelsEpic,
} from "./ContainerDetails/effects";
import {
  chemicalReducer,
  fetchChemicalEpic,
  chemicalContainersReducer,
  fetchChemicalContainersEpic,
} from "./ChemicalDetails/effects";
import { editChemicalEpic, refreshChemicalEpic } from "./ChemicalEdit/effects";
import {
  editContainerEpic,
  refreshContainerEpic,
} from "./ContainerEdit/effects";
import {
  containerSizeReducer,
  fetchContainerSizeEpic,
  containerSizeContainersReducer,
  fetchContainerSizeContainersEpic,
} from "./ContainerSizeDetails/effects";
import {
  refreshContainerSizeEpic,
  editContainerSizeEpic,
} from "./ContainerSizeEdit/effects";
import { devicesReducer, fetchDevicesEpic } from "./Devices/effects";
import {
  customerReducer,
  fetchCustomerEpic,
  customerLocationsReducer,
  fetchCustomerLocationsEpic,
} from "./CustomerDetails/effects";
import { editCustomerEpic, refreshCustomerEpic } from "./CustomerEdit/effects";
import {
  locationReducer,
  fetchLocationEpic,
  locationContainersReducer,
  fetchLocationContainersEpic,
} from "./LocationDetails/effects";
import { editLocationEpic, refreshLocationEpic } from "./LocationEdit/effects";
import { userReducer, fetchUserEpic } from "./UserDetails/effects";
import { editUserEpic, refreshUserEpic } from "./UserEdit/effects";

export const rootEpic = combineEpics(
  loginEpic,
  checkAuthEpic,
  authEpic,
  noAuthEpic,
  fetchChemicalsEpic,
  createChemicalEpic,
  refreshChemicalsEpic,
  createContainerSizeEpic,
  refreshContainerSizesEpic,
  fetchContainerSizesEpic,
  fetchCustomersEpic,
  refreshCustomersEpic,
  createCustomerEpic,
  fetchLocationsEpic,
  createLocationEpic,
  refreshLocationsEpic,
  createUserEpic,
  fetchUsersEpic,
  refreshUsersEpic,
  createContainerEpic,
  fetchContainersEpic,
  editContainerEpic,
  refreshContainersEpic,
  purchaseEpic,
  fetchContainerEpic,
  refreshContainerEpic,
  fetchContainerLevelsEpic,
  fetchChemicalEpic,
  editChemicalEpic,
  refreshChemicalEpic,
  editContainerEpic,
  refreshChemicalEpic,
  fetchContainerSizeEpic,
  refreshContainerSizeEpic,
  editContainerSizeEpic,
  fetchDevicesEpic,
  fetchCustomerEpic,
  editCustomerEpic,
  refreshCustomerEpic,
  editLocationEpic,
  refreshLocationEpic,
  fetchLocationEpic,
  fetchUserEpic,
  editUserEpic,
  refreshUserEpic,
  fetchChemicalContainersEpic,
  fetchContainerSizeContainersEpic,
  fetchLocationContainersEpic,
  fetchCustomerLocationsEpic
);

export const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    chemicals: chemicalsReducer,
    containerSizes: containerSizesReducer,
    customers: customersReducer,
    locations: locationsReducer,
    users: usersReducer,
    containers: containersReducer,
    currentContainer: containerReducer,
    currentChemical: chemicalReducer,
    currentContainerSize: containerSizeReducer,
    devices: devicesReducer,
    currentUser: userReducer,
    currentLocation: locationReducer,
    currentCustomer: customerReducer,
    chemicalContainers: chemicalContainersReducer,
    containerSizeContainers: containerSizeContainersReducer,
    locationContainers: locationContainersReducer,
    customerLocations: customerLocationsReducer,
  });

export type AppState = ReturnType<ReturnType<typeof createRootReducer>>;
