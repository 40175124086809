import * as React from "react";
import { SiteNav } from "../SiteNav/SiteNav";

export const DefaultLayout: React.SFC = (props) => (
  <>
    <SiteNav />
    <div className="main-content">
      <div className="container-fluid">
        <div className="row justify-content-center">{props.children}</div>
      </div>
    </div>
  </>
);
