import { map, switchMap, tap, filter } from "rxjs/operators";
import { from, Observable } from "rxjs";
import { ofType, Epic } from "redux-observable";
import { push } from "connected-react-router";
import fb from "../firebase";
import firebase from "firebase";

const Authenticate = async ({ email, password }) => {
  try {
    const creds = await fb.auth().signInWithEmailAndPassword(email, password);
    return loginSuccessful();
  } catch (err) {
    return loginFailure(err.message);
  }
};

const onAuthStateChanged$ = Observable.create((obs) => {
  return firebase.auth().onAuthStateChanged(
    (user) => obs.next(user),
    (err) => obs.error(err),
    () => obs.complete()
  );
});

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESSFUL = "LOGIN_SUCCESSFUL";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const CHECK_AUTH = "CHECK_AUTH";
export const CHECK_AUTH_SUCCESSFUL = "CHECK_AUTH_SUCCESSFUL";

export const checkAuth = () => ({
  type: CHECK_AUTH,
});

export const checkAuthSuccessful = () => ({
  type: CHECK_AUTH_SUCCESSFUL,
});

export const login = (email, password) => ({
  type: LOGIN,
  payload: {
    email,
    password,
  },
});

export const loginSuccessful = () => ({
  type: LOGIN_SUCCESSFUL,
});

export const loginFailure = (message) => ({
  type: LOGIN_FAILURE,
  payload: message,
});

export const loginEpic = (action$) =>
  action$.pipe(
    ofType(LOGIN),
    switchMap((action: any) => from(Authenticate(action.payload)))
  );

export const checkAuthEpic = (action$) =>
  action$.pipe(
    ofType(CHECK_AUTH),
    switchMap((action: any) => onAuthStateChanged$),
    map((user) => {
      if (user) return loginSuccessful();
      return checkAuthSuccessful();
    })
  );

export const noAuthEpic = (action$) =>
  action$.pipe(
    ofType(CHECK_AUTH_SUCCESSFUL),
    map(() => push("/login"))
  );

export const authEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOGIN_SUCCESSFUL),
    filter(() => {
      return state$?.value?.router?.location?.pathname === "/login";
    }),
    map(() => push("/"))
  );

export const authReducer = (
  state = { authenticated: null, loading: false, error: null },
  action
) => {
  switch (action.type) {
    case CHECK_AUTH_SUCCESSFUL:
      return {
        ...state,
        authenticated: false,
      };
    case LOGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESSFUL:
      return { authenticated: true, loading: false, error: null };
    case LOGIN_FAILURE:
      return { authenticated: true, loading: false, error: action.payload };
    default:
      return state;
  }
};
