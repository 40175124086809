import { map, switchMap, tap } from "rxjs/operators";
import { from } from "rxjs";
import { ofType } from "redux-observable";
import API from "../api";
import { fetchContainer } from "../ContainerDetails/effects";

export const EDIT_CONTAINER = "EDIT_CONTAINER";
export const EDIT_CONTAINER_SUCCESSFUL = "EDIT_CONTAINER_SUCCESSFUL";

const updateContainer = ({ id, container }) => {
  console.log(container);
  return API.post(`containers/${id}`, {
    json: container,
  }).then(() => editContainerSuccessful());
};

export const editContainer = (id, container) => ({
  type: EDIT_CONTAINER,
  payload: {
    id,
    container,
  },
});

export const editContainerSuccessful = () => ({
  type: EDIT_CONTAINER_SUCCESSFUL,
});

export const editContainerEpic = (action$) =>
  action$.pipe(
    ofType(EDIT_CONTAINER),
    switchMap((action: any) => from(updateContainer(action.payload)))
  );

export const refreshContainerEpic = (action$, $state) =>
  action$.pipe(
    ofType(EDIT_CONTAINER_SUCCESSFUL),
    map((action) => fetchContainer($state.value.currentContainer.id))
  );
