export function withDefault(param, defaultValue, includeNull) {
    if (includeNull === void 0) { includeNull = true; }
    var decodeWithDefault = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var decodedValue = param.decode.apply(param, args);
        if (decodedValue === undefined) {
            return defaultValue;
        }
        if (includeNull) {
            if (decodedValue === null) {
                return defaultValue;
            }
            else {
                return decodedValue;
            }
        }
        return decodedValue;
    };
    return { encode: param.encode, decode: decodeWithDefault };
}
export default withDefault;
