import * as firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyARAY80JNfKiitE9nkclTVjh_QgGFEdGQE",
  authDomain: "clean-authority-241200.firebaseapp.com",
};

const app = firebase.initializeApp(firebaseConfig);

export default app;
