import * as React from "react";
import { DefaultLayout } from "../_components/DefaultLayout";
import { Header } from "../_components/Header";
import {
  Progress,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  CardHeader,
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Col,
  Input,
} from "reactstrap";
import { fetchCustomer, fetchCustomerLocations } from "./effects";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { useParams } from "react-router-dom";
import { CustomerEdit } from "../CustomerEdit/CustomerEdit";

const ListItem = (props) => {
  return (
    <tr onClick={props.onClick}>
      <td>{props.name}</td>
      <td>{props.address}</td>
      <td>{props.numberOfContainers}</td>
      <td>
        <div className="badge badge-soft-success">{props.status}</div>
      </td>
      <td className="text-right">
        <div className="dropdown">
          <a href="#" className="dropdown-ellipses dropdown-toggle">
            <i className="fe fe-more-vertical"></i>
          </a>
        </div>
      </td>
    </tr>
  );
};

const CustomerDetailsView = () => {
  const [modal, setModal] = React.useState(false);
  let { id } = useParams();

  const customer: any = useSelector<any>((state) => state.currentCustomer);
  const locations: any = useSelector<any>((state) => state.customerLocations);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchCustomer(id));
    dispatch(fetchCustomerLocations(id));
  }, [id]);

  const toggle = () => setModal(!modal);

  const viewDetail = React.useCallback(
    (id) => dispatch(push(`/locations/${id}`)),
    [dispatch]
  );

  return (
    <DefaultLayout>
      {" "}
      <div className="col-12">
        <Header title={customer?.name} pretitle="CUSTOMER">
          <Button className="lift" onClick={toggle}>
            Edit Customer
          </Button>
        </Header>
        <div className="row">
          <div className="col-12 col-xl-8">
            <div className="card">
              <div className="card-header">
                <h4 className="card-header-title">Locations</h4>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-sm table-nowrap card-table  table-hover">
                    <thead>
                      <tr>
                        <th>
                          <a href="#" className="text-muted sort">
                            Name
                          </a>
                        </th>
                        <th>
                          <a href="#" className="text-muted sort">
                            Address
                          </a>
                        </th>
                        <th>
                          <a href="#" className="text-muted sort">
                            # of Containers
                          </a>
                        </th>
                        <th colSpan={2}>
                          <a href="#" className="text-muted sort">
                            Status
                          </a>
                        </th>
                      </tr>
                    </thead>

                    <tbody className="list">
                      {locations.rows.map((item) => (
                        <ListItem
                          key={item.id}
                          name={item.name}
                          address={`${item.street1} ${item.street2} ${item.city}, ${item.state} ${item.zip}`}
                          numberOfContainers={item.containerCount}
                          status={item.deletedAt ? "Inactive" : "Active"}
                          onClick={() => viewDetail(item.id)}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-xl-4">
            <div className="card">
              <div className="card-body">
                <div className="list-group list-group-flush my-n3">
                  <div className="list-group-item">
                    <div className="row align-items-center">
                      <div className="col">
                        <h5 className="mb-0">Name</h5>
                      </div>
                      <div className="col-auto">{customer?.name}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <CustomerEdit isOpen={modal} toggle={toggle} />
      </div>
    </DefaultLayout>
  );
};

export const CustomerDetails = () => <CustomerDetailsView />;
