import { map, switchMap, tap } from "rxjs/operators";
import { from } from "rxjs";
import { ofType } from "redux-observable";
import API from "../api";

export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_SUCCESSFUL = "FETCH_USER_SUCCESSFUL";

const getUser = (id) =>
  API.get(`users/${id}`)
    .json<any>()
    .then((user = {}) => fetchUserSuccessful(user));

export const fetchUser = (id) => ({
  type: FETCH_USER,
  payload: id,
});

export const fetchUserSuccessful = (user: any) => ({
  type: FETCH_USER_SUCCESSFUL,
  payload: user,
});

export const fetchUserEpic = (action$) =>
  action$.pipe(
    ofType(FETCH_USER),
    switchMap((action: any) => from(getUser(action.payload)))
  );

export const userReducer = (state = {}, action: any) => {
  switch (action.type) {
    case FETCH_USER_SUCCESSFUL:
      return action.payload;
    default:
      return state;
  }
};
