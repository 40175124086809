import { map, switchMap, tap } from "rxjs/operators";
import { from } from "rxjs";
import { ofType } from "redux-observable";
import API from "../api";
import { fetchContainers } from "../ContainerList/effects";

export const CREATE_CONTAINER = "CREATE_CONTAINER";
export const CREATE_CONTAINER_SUCCESSFUL = "CREATE_CONTAINER_SUCCESSFUL";

const addContainer = (container) =>
  API.post("containers", { json: container })
    .json<any>()
    .then((containers = []) => createContainerSuccessful());

export const createContainer = (container) => ({
  type: CREATE_CONTAINER,
  payload: container,
});

export const createContainerSuccessful = () => ({
  type: CREATE_CONTAINER_SUCCESSFUL,
});

export const createContainerEpic = (action$) =>
  action$.pipe(
    ofType(CREATE_CONTAINER),
    switchMap((action: any) => from(addContainer(action.payload)))
  );

export const refreshContainersEpic = (action$) =>
  action$.pipe(
    ofType(CREATE_CONTAINER_SUCCESSFUL),
    map((action) => fetchContainers())
  );
