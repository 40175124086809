import { map, switchMap, tap } from "rxjs/operators";
import { from } from "rxjs";
import { ofType } from "redux-observable";
import { push } from "connected-react-router";
import API from "../api";

export const FETCH_CHEMICALS = "FETCH_CHEMICALS";
export const FETCH_CHEMICALS_SUCCESSFUL = "FETCH_CHEMICALS_SUCCESSFUL";

const getChemicals = (options) =>
  API.get("chemicals", {
    searchParams: options,
  })
    .json<any>()
    .then((chemicals = []) => fetchChemicalsSuccessful(chemicals));

export const fetchChemicals = (options = {}) => ({
  type: FETCH_CHEMICALS,
  payload: options,
});

export const fetchChemicalsSuccessful = (chemicals: any) => ({
  type: FETCH_CHEMICALS_SUCCESSFUL,
  payload: chemicals,
});

export const fetchChemicalsEpic = (action$) =>
  action$.pipe(
    ofType(FETCH_CHEMICALS),
    switchMap((action: any) => from(getChemicals(action.payload)))
  );

export const chemicalsReducer = (
  state = { rows: [], count: 0, loading: true },
  action: any
) => {
  switch (action.type) {
    case FETCH_CHEMICALS:
      return {
        ...state,
        rows: [],
        count: 0,
        loading: true,
      };
    case FETCH_CHEMICALS_SUCCESSFUL:
      return {
        ...action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
