import { map, switchMap, tap } from "rxjs/operators";
import { from } from "rxjs";
import { ofType } from "redux-observable";
import API from "../api";

export const FETCH_CUSTOMER = "FETCH_CUSTOMER";
export const FETCH_CUSTOMER_SUCCESSFUL = "FETCH_CUSTOMER_SUCCESSFUL";

export const FETCH_CUSTOMER_LOCATIONS = "FETCH_CUSTOMER_LOCATIONS";
export const FETCH_CUSTOMER_LOCATIONS_SUCCESSFUL =
  "FETCH_CUSTOMER_LOCATIONS_SUCCESSFUL";

const getCustomer = (id) =>
  API.get(`customers/${id}`)
    .json<any>()
    .then((customer = {}) => fetchCustomerSuccessful(customer));

const getCustomerLocations = (id) =>
  API.get(`customers/${id}/locations`)
    .json<any>()
    .then((locations = {}) => fetchCustomerLocationsSuccessful(locations));

export const fetchCustomer = (id) => ({
  type: FETCH_CUSTOMER,
  payload: id,
});

export const fetchCustomerSuccessful = (customer: any) => ({
  type: FETCH_CUSTOMER_SUCCESSFUL,
  payload: customer,
});

export const fetchCustomerLocations = (id) => ({
  type: FETCH_CUSTOMER_LOCATIONS,
  payload: id,
});

export const fetchCustomerLocationsSuccessful = (customer: any) => ({
  type: FETCH_CUSTOMER_LOCATIONS_SUCCESSFUL,
  payload: customer,
});

export const fetchCustomerEpic = (action$) =>
  action$.pipe(
    ofType(FETCH_CUSTOMER),
    switchMap((action: any) => from(getCustomer(action.payload)))
  );

export const fetchCustomerLocationsEpic = (action$) =>
  action$.pipe(
    ofType(FETCH_CUSTOMER_LOCATIONS),
    switchMap((action: any) => from(getCustomerLocations(action.payload)))
  );

export const customerReducer = (state = {}, action: any) => {
  switch (action.type) {
    case FETCH_CUSTOMER_SUCCESSFUL:
      return action.payload;
    default:
      return state;
  }
};

export const customerLocationsReducer = (state = { rows: [] }, action: any) => {
  switch (action.type) {
    case FETCH_CUSTOMER_LOCATIONS_SUCCESSFUL:
      return action.payload;
    default:
      return state;
  }
};
