import { map, switchMap, tap } from "rxjs/operators";
import { from } from "rxjs";
import { ofType } from "redux-observable";
import API from "../api";
import { fetchChemicals } from "../ChemicalList/effects";

export const CREATE_CHEMICAL = "CREATE_CHEMICAL";
export const CREATE_CHEMICAL_SUCCESSFUL = "CREATE_CHEMICAL_SUCCESSFUL";

const addChemical = (chemical) =>
  API.post("chemicals", { json: chemical })
    .json<any>()
    .then((chemicals = []) => createChemicalSuccessful());

export const createChemical = (chemical) => ({
  type: CREATE_CHEMICAL,
  payload: chemical,
});

export const createChemicalSuccessful = () => ({
  type: CREATE_CHEMICAL_SUCCESSFUL,
});

export const createChemicalEpic = (action$) =>
  action$.pipe(
    ofType(CREATE_CHEMICAL),
    switchMap((action: any) => from(addChemical(action.payload)))
  );

export const refreshChemicalsEpic = (action$) =>
  action$.pipe(
    ofType(CREATE_CHEMICAL_SUCCESSFUL),
    map((action) => fetchChemicals())
  );
