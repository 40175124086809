import * as React from "react";
import { DefaultLayout } from "../_components/DefaultLayout";
import { Header } from "../_components/Header";

import { Button, Spinner, Badge } from "reactstrap";
import { fetchContainer, fetchContainerLevels, purchase } from "./effects";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { ContainerEdit } from "../ContainerEdit/ContainerEdit";
import { formatDistanceToNow, format, addDays } from "date-fns";
import {
  VictoryArea,
  VictoryChart,
  VictoryAxis,
  VictoryTheme,
  VictoryThemeDefinition,
  VictoryTooltip,
  VictoryContainer,
  VictoryScatter,
  VictoryLabel,
} from "victory";
import { NumberCard } from "../Graphs/NumberCard";
import { AppState } from "../root";
import firebase from "firebase";

const colors = [
  "#252525",
  "#525252",
  "#737373",
  "#969696",
  "#bdbdbd",
  "#d9d9d9",
  "#f0f0f0",
];
const charcoal = "#252525";
const grey = "#969696";

// Typography
const letterSpacing = "normal";
const fontSize = 10;

// Layout
const baseProps = {
  padding: 0,
  colorScale: colors,
};

// Labels
const baseLabelStyles = {
  font: '"Cerebri Sans", sans-serif',
  fontSize,
  letterSpacing,
  padding: 2,
  fill: charcoal,
  stroke: "transparent",
};

const centeredLabelStyles = { ...baseLabelStyles, textAnchor: "middle" };

// Strokes
const strokeDasharray = "6, 5";
const strokeLinecap = "round";
const strokeLinejoin = "round";

// Put it all together...
const areaTheme: VictoryThemeDefinition = {
  area: {
    ...baseProps,
    style: {
      data: {
        fill: "#1FA6C2",
      },
      labels: baseLabelStyles,
    },
  },

  axis: {
    style: {
      axis: {
        fill: "transparent",
        stroke: charcoal,
        strokeWidth: 1,
        strokeLinecap,
        strokeLinejoin,
      },
      axisLabel: { ...centeredLabelStyles, padding: 0 } as React.CSSProperties,
      grid: {
        fill: "none",
        stroke: "#EFEFEF",
        strokeDasharray,
        strokeLinecap,
        strokeLinejoin,
        pointerEvents: "painted",
      },
      ticks: {
        fill: "transparent",
        size: 1,
        stroke: "transparent",
      },
      tickLabels: baseLabelStyles,
    },
    ...baseProps,
  },
  tooltip: {
    style: { padding: 0, pointerEvents: "none", ...baseLabelStyles },
    flyoutStyle: {
      stroke: charcoal,
      strokeWidth: 0.5,
      fill: "#fff",
      pointerEvents: "none",
    },
    flyoutPadding: 2,
    cornerRadius: 0,
    pointerLength: 5,
  },
};

const LevelHistoryChart = ({ levels, containerId }) => (
  <VictoryChart
    theme={{
      ...areaTheme,
    }}
    height={150}
    scale={{ x: "time" }}
    padding={25}
  >
    <VictoryArea data={levels} key="id" x="timestamp" y="percentage" />

    <VictoryAxis
      dependentAxis
      standalone={false}
      tickValues={[0, 50, 100]}
      tickFormat={(y) => y + "%"}
    />
    <VictoryAxis
      standalone={false}
      // tickValues={new Array(14).map((x, index) => addDays(new Date(), -index))}
      tickFormat={(x) => format(x, "H:mm aa")}
    />
    <VictoryScatter
      x="timestamp"
      y="percentage"
      size={1}
      data={levels}
      labels={({ datum }) =>
        `Level: ~${datum.percentage} ml \n ${format(
          datum.timestamp,
          "MM/dd/yy HH:mm aa"
        )}`
      }
      labelComponent={<VictoryTooltip />}
    />
  </VictoryChart>
);

const ContainerDetailsView = () => {
  const [modal, setModal] = React.useState(false);
  let { id } = useParams<{ id: any }>();

  const container: any = useSelector<AppState>(
    (state) => state.currentContainer
  );
  const user: any = firebase.auth().currentUser;

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchContainer(id));
    dispatch(fetchContainerLevels(id));
  }, [id]);

  const toggle = () => setModal(!modal);

  return (
    <DefaultLayout>
      <div className="col-12">
        <Header title={`${container?.name ?? " "}`} pretitle="Container">
          {container.loading || (
            <React.Fragment>
              <Button
                className="lift mr-2"
                onClick={() => {
                  dispatch(
                    purchase({
                      client: container?.location?.name,
                      chemicalName: container?.chemical?.name,
                      containerSize: container?.container_size?.name,
                    })
                  );
                }}
              >
                Purchase
              </Button>
              <Button className="lift" onClick={toggle}>
                Edit Container
              </Button>
            </React.Fragment>
          )}
        </Header>

        {container.loading ? (
          <div className="text-center mt-6">
            <Spinner size="xl" />
          </div>
        ) : (
          <React.Fragment>
            <div className="row">
              <div className="col-12 col-xl-8">
                <div className="row">
                  <div className="col-4">
                    <NumberCard title="Estimated Next Swap" icon="target">
                      July 12th, 2021
                    </NumberCard>
                  </div>

                  <div className="col-4">
                    <NumberCard title="Washes Since Swap" icon="truck">
                      1281
                    </NumberCard>
                  </div>

                  <div className="col-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="row align-items-center">
                          <div className="col">
                            <h6 className="text-uppercase text-muted mb-2">
                              Estimated Average Dosage
                            </h6>
                            <span className="h2 mb-0">57ml</span>
                          </div>
                          <div className="col-auto">
                            <Badge color="warning">+/- 2%</Badge>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-header-title">Recent Activity</h4>
                  </div>
                  <div className="card-body">
                    <div className="list-group list-group-flush">
                      <div className="list-group-item">
                        <div className="row">
                          <div className="col-auto">
                            <div className="avatar avatar-sm">
                              <div className="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                                <i className="fe fe-database"></i>
                              </div>
                            </div>
                          </div>
                          <div className="col ml-n2">
                            <p className="small mb-0">
                              <strong>Chemical Swapped</strong> was detected.
                            </p>
                            <small className="text-muted">1 month ago</small>
                          </div>
                        </div>
                      </div>

                      <div className="list-group-item">
                        <div className="row">
                          <div className="col-auto">
                            <div className="avatar avatar-sm">
                              <div className="avatar-title font-size-lg bg-danger-soft rounded-circle text-primary">
                                <i className="fe fe-alert-triangle"></i>
                              </div>
                            </div>
                          </div>
                          <div className="col ml-n2">
                            <p className="small mb-0">
                              <strong>Low Inventory</strong> - an alarm was sent
                              out.
                            </p>
                            <small className="text-muted">1 month ago</small>
                          </div>
                        </div>
                      </div>
                      <div className="list-group-item">
                        <div className="row">
                          <div className="col-auto">
                            <div className="avatar avatar-sm">
                              <div className="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                                <i className="fe fe-database"></i>
                              </div>
                            </div>
                          </div>
                          <div className="col ml-n2">
                            <p className="small mb-0">
                              <strong>Chemical Swapped</strong> was detected.
                            </p>

                            <small className="text-muted">3 months ago</small>
                          </div>
                        </div>
                      </div>

                      <div className="list-group-item">
                        <div className="row">
                          <div className="col-auto">
                            <div className="avatar avatar-sm">
                              <div className="avatar-title font-size-lg bg-danger-soft rounded-circle text-primary">
                                <i className="fe fe-alert-triangle"></i>
                              </div>
                            </div>
                          </div>
                          <div className="col ml-n2">
                            <p className="small mb-0">
                              <strong>Low Inventory</strong> - an alarm was sent
                              out.
                            </p>
                            <small className="text-muted">3 month ago</small>
                          </div>
                        </div>
                      </div>
                      <div className="list-group-item">
                        <div className="row">
                          <div className="col-auto">
                            <div className="avatar avatar-sm">
                              <div className="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                                <i className="fe fe-database"></i>
                              </div>
                            </div>
                          </div>
                          <div className="col ml-n2">
                            <p className="small mb-0">
                              <strong>Chemical Swapped</strong> was detected.
                            </p>

                            <small className="text-muted">6 months ago</small>
                          </div>
                        </div>
                      </div>

                      <div className="list-group-item">
                        <div className="row">
                          <div className="col-auto">
                            <div className="avatar avatar-sm">
                              <div className="avatar-title font-size-lg bg-danger-soft rounded-circle text-primary">
                                <i className="fe fe-alert-triangle"></i>
                              </div>
                            </div>
                          </div>
                          <div className="col ml-n2">
                            <p className="small mb-0">
                              <strong>Low Inventory</strong> - an alarm was sent
                              out.
                            </p>
                            <small className="text-muted">3 month ago</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="card-body text-center">
                    <Spinner size="xl" />
                  </div> */}
                </div>
              </div>
              <div className="col-12 col-xl-4">
                <div className="card">
                  <div className="card-body">
                    <div className="list-group list-group-flush my-n3">
                      <div className="list-group-item">
                        <div className="row align-items-center">
                          <div className="col">
                            <h5 className="mb-0">Name</h5>
                          </div>
                          <div className="col-auto">{container?.name}</div>
                        </div>
                      </div>
                      <div className="list-group-item">
                        <div className="row align-items-center">
                          <div className="col">
                            <h5 className="mb-0">Chemical</h5>
                          </div>
                          <div className="col-auto">
                            <Link
                              to={`/chemicals/${container?.chemical?.id}`}
                              className="nav-link p-0"
                            >
                              {container?.chemical?.name}
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="list-group-item">
                        <div className="row align-items-center">
                          <div className="col">
                            <h5 className="mb-0">Container Size</h5>
                          </div>
                          <div className="col-auto">
                            <Link
                              to={`/sizes/${container?.container_size?.id}`}
                              className="nav-link p-0"
                            >
                              {container?.container_size?.name}
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="list-group-item">
                        <div className="row align-items-center">
                          <div className="col">
                            <h5 className="mb-0">Location</h5>
                          </div>
                          <div className="col-auto">
                            <Link
                              to={`/locations/${container?.location?.id}`}
                              className="nav-link p-0"
                            >
                              {container?.location?.name}
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="list-group-item">
                        <div className="row align-items-center">
                          <div className="col">
                            <h5 className="mb-0">Device</h5>
                          </div>
                          <div className="col-auto">
                            {container?.device?.id}
                          </div>
                        </div>
                      </div>
                      {/* <div className="list-group-item">
                        <div className="row align-items-center">
                          <div className="col">
                            <h5 className="mb-0">Last Updated</h5>
                          </div>
                          <div className="col-auto">
                            {container?.container_level?.timestamp
                              ? formatDistanceToNow(
                                  new Date(container.container_level.timestamp)
                                )
                              : "No data"}
                          </div>
                        </div>
                      </div> */}
                      <div className="list-group-item">
                        <div className="row align-items-center">
                          <div className="col">
                            <h5 className="mb-0">Status</h5>
                          </div>
                          <div className="col-auto">
                            <Badge color="success">Active</Badge>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="card">
                  <div className="card-header">
                    <h4 className="card-header-title">Raw Data History</h4>
                  </div>
                  <div className="card-body text-center">
                    {container.levels ? (
                      <LevelHistoryChart
                        levels={container.levels}
                        containerId={id}
                      />
                    ) : (
                      <Spinner size="xl" className="mt-2" />
                    )}
                  </div>
                </div> */}
              </div>
            </div>

            <ContainerEdit isOpen={modal} toggle={toggle} />
          </React.Fragment>
        )}
      </div>
    </DefaultLayout>
  );
};

export const ContainerDetails = () => <ContainerDetailsView />;
