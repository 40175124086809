import React from "react";

export const NumberCard = ({ title, icon, children }) => (
  <div className="card">
    <div className="card-body">
      <div className="row align-items-center">
        <div className="col">
          <h6 className="text-uppercase text-muted mb-2">{title}</h6>
          <span className="h2 mb-0">{children}</span>
        </div>
        <div className="col-auto">
          <span className={`h2 fe fe-${icon} text-muted mb-0`}></span>
        </div>
      </div>
    </div>
  </div>
);
