import * as React from "react";
import Fuse from "fuse.js";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { fetchLocations } from "./effects";
import { LocationCreate } from "../LocationCreate/LocationCreate";
import { DefaultLayout } from "../_components/DefaultLayout";
import { Header } from "../_components/Header";
import {
  useQueryParam,
  StringParam,
  withDefault,
  NumberParam,
  BooleanParam,
} from "use-query-params";
import MapGL, { Marker } from "react-map-gl";
import {
  Pagination,
  PaginationItem,
  PaginationLink,
  Spinner,
} from "reactstrap";
import { BasePagination } from "../_components/BasePagination";
import MARKER_STYLE from "./marker-style";
import { useDebounce } from "use-debounce";
import { Link } from "react-router-dom";

type viewportState = {
  latitude: number;
  longitude: number;
  zoom: number;
  bearing: number;
  pitch: number;
};

const ListItem = (props) => {
  return (
    <tr onClick={props.onClick}>
      <td>{props.name}</td>
      <td>{props.customer}</td>
      <td>{props.address}</td>
      <td>{props.numberOfContainers}</td>
      <td>
        <div className="badge badge-soft-success">{props.status}</div>
      </td>
      <td className="text-right">
        <div className="dropdown">
          <a href="#" className="dropdown-ellipses dropdown-toggle">
            <i className="fe fe-more-vertical"></i>
          </a>
        </div>
      </td>
    </tr>
  );
};

const LocationListView = () => {
  const [modal, setModal] = React.useState(false);
  const [viewport, setViewport] = React.useState<viewportState>({
    latitude: 30.8283,
    longitude: -99.5795,
    zoom: 4,
    bearing: 0,
    pitch: 0,
  });
  const [searchTerm, setSearchTerm] = useQueryParam(
    "q",
    withDefault(StringParam, "")
  );
  const [searchTermDebounced] = useDebounce(searchTerm, 1000);
  const [sort, setSort] = useQueryParam(
    "sort",
    withDefault(StringParam, "name")
  );
  const [page, setPage] = useQueryParam("page", withDefault(NumberParam, 1));
  const [sortReverse, setSortReverse] = useQueryParam(
    "sortReverse",
    withDefault(BooleanParam, false)
  );
  const locations: any = useSelector<any>((state) => state.locations);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      fetchLocations({
        limit: 10,
        page,
        sort,
        sortReverse,
        q: searchTermDebounced,
      })
    );
  }, [dispatch, page, sort, sortReverse, searchTermDebounced]);

  const viewDetail = React.useCallback(
    (id) => dispatch(push(`/locations/${id}`)),
    [dispatch]
  );

  const toggle = () => setModal(!modal);

  const onViewportChange = (newViewport: viewportState) =>
    setViewport({ ...newViewport });

  const renderMarker = (location, i) => {
    const { name, lat, lon } = location;
    console.log(location);
    return (
      <Marker
        key={i}
        longitude={lon}
        latitude={lat}
        captureDrag={false}
        captureDoubleClick={false}
      >
        <Link to={`/locations/${location?.id}`}>
          <div className="location">
            <span>{location.name}</span>
          </div>
        </Link>
      </Marker>
    );
  };

  const maxPages = Math.floor(locations.count / 10) + 1;
  return (
    <DefaultLayout>
      {" "}
      <div className="col-12">
        <Header title="Locations" pretitle="Overview">
          {locations.loading || (
            <a href="#!" className="btn btn-primary lift" onClick={toggle}>
              Add Location
            </a>
          )}
        </Header>
        {locations.loading ? (
          <div className="text-center mt-6">
            <Spinner size="xl" />
          </div>
        ) : (
          <React.Fragment>
            <div className="card" style={{ height: 400 }}>
              <MapGL
                {...viewport}
                width="100%"
                height="100%"
                mapStyle="mapbox://styles/mapbox/light-v9"
                onViewportChange={onViewportChange}
                mapboxApiAccessToken={
                  "pk.eyJ1Ijoic3V0cmFpbm5vdmF0aW9ucyIsImEiOiJja2diYnk5dTgwZmd5MnhyeXYwcjl5czhjIn0.p2UCmgXjpqIc8XRRtqDcDA"
                }
              >
                <style>{MARKER_STYLE}</style>
                {locations.rows.map(renderMarker)}
              </MapGL>
            </div>
            <div className="card">
              <div className="card-header">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="input-group input-group-flush">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fe fe-search"></i>
                      </span>
                    </div>
                    <input
                      className="form-control search"
                      type="search"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </form>
              </div>
              <div className="table-responsive">
                <table className="table table-sm table-nowrap card-table  table-hover">
                  <thead>
                    <tr>
                      <th>
                        <a
                          data-sort="tables-row"
                          onClick={() => setSort("name")}
                          className="text-muted sort"
                        >
                          Name
                        </a>
                      </th>
                      <th>
                        <a
                          data-sort="tables-row"
                          onClick={() => setSort("$customer.name$")}
                          className="text-muted sort"
                        >
                          Customer
                        </a>
                      </th>
                      <th>
                        <a className="text-muted sort">Address</a>
                      </th>
                      <th>
                        <a
                          data-sort="tables-row"
                          onClick={() => setSort("$containerCount$")}
                          className="text-muted sort"
                        >
                          # of Containers
                        </a>
                      </th>
                      <th colSpan={2}>
                        <a
                          data-sort="tables-row"
                          onClick={() => setSort("status")}
                          className="text-muted sort"
                        >
                          Status
                        </a>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {locations.rows.map((item) => (
                      <ListItem
                        key={item.id}
                        name={item.name}
                        address={`${item.street1 || ""} ${item.street2 || ""} ${
                          item.city || ""
                        }, ${item.state || ""} ${item.zip || ""}`}
                        numberOfContainers={item.containerCount}
                        customer={item?.customer?.name}
                        status={item.deletedAt ? "Inactive" : "Active"}
                        onClick={() => viewDetail(item.id)}
                      />
                    ))}
                  </tbody>
                </table>
                <BasePagination
                  page={page}
                  maxPages={maxPages}
                  setPage={setPage}
                />
              </div>
            </div>

            <LocationCreate isOpen={modal} toggle={toggle} />
          </React.Fragment>
        )}
      </div>
    </DefaultLayout>
  );
};

export const LocationList = () => <LocationListView />;
