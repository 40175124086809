import { map, switchMap, tap } from "rxjs/operators";
import { from } from "rxjs";
import { ofType } from "redux-observable";
import API from "../api";
import { fetchCustomers } from "../CustomerList/effects";

export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const CREATE_CUSTOMER_SUCCESSFUL = "CREATE_CUSTOMER_SUCCESSFUL";

const addCustomer = (customer) =>
  API.post("customers", { json: customer })
    .json<any>()
    .then((customers = []) => createCustomerSuccessful());

export const createCustomer = (customer) => ({
  type: CREATE_CUSTOMER,
  payload: customer,
});

export const createCustomerSuccessful = () => ({
  type: CREATE_CUSTOMER_SUCCESSFUL,
});

export const createCustomerEpic = (action$) =>
  action$.pipe(
    ofType(CREATE_CUSTOMER),
    switchMap((action: any) => from(addCustomer(action.payload)))
  );

export const refreshCustomersEpic = (action$) =>
  action$.pipe(
    ofType(CREATE_CUSTOMER_SUCCESSFUL),
    map((action) => fetchCustomers())
  );
