import { map, switchMap, tap } from "rxjs/operators";
import { from } from "rxjs";
import { ofType } from "redux-observable";
import API from "../api";

export const FETCH_CONTAINERS = "FETCH_CONTAINERS";
export const FETCH_CONTAINERS_SUCCESSFUL = "FETCH_CONTAINERS_SUCCESSFUL";

const getContainers = (options) =>
  API.get("containers", {
    searchParams: options,
  })
    .json<any>()
    .then((containers = []) => fetchContainersSuccessful(containers));

export const fetchContainers = (page?, sort?, sortReverse?, q?) => ({
  type: FETCH_CONTAINERS,
  payload: {
    page,
    sort,
    sortReverse,
    q,
  },
});

export const fetchContainersSuccessful = (containers: any) => ({
  type: FETCH_CONTAINERS_SUCCESSFUL,
  payload: containers,
});

export const fetchContainersEpic = (action$) =>
  action$.pipe(
    ofType(FETCH_CONTAINERS),
    switchMap((action: any) => from(getContainers(action.payload)))
  );

export const containersReducer = (
  state = { rows: [], count: 0, loading: true },
  action: any
) => {
  switch (action.type) {
    case FETCH_CONTAINERS:
      return {
        ...state,
        rows: [],
        count: 0,
        loading: true,
      };
    case FETCH_CONTAINERS_SUCCESSFUL:
      return {
        ...action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
