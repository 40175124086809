import { map, switchMap, tap } from "rxjs/operators";
import { from } from "rxjs";
import { ofType } from "redux-observable";
import API from "../api";
import { fetchChemical } from "../ChemicalDetails/effects";

export const EDIT_CHEMICAL = "EDIT_CHEMICAL";
export const EDIT_CHEMICAL_SUCCESSFUL = "EDIT_CHEMICAL_SUCCESSFUL";

const updateChemical = ({ id, chemical }) =>
  API.post(`chemicals/${id}`, { json: chemical }).then((chemical) =>
    editChemicalSuccessful()
  );

export const editChemical = (id, chemical) => ({
  type: EDIT_CHEMICAL,
  payload: {
    id,
    chemical,
  },
});

export const editChemicalSuccessful = () => ({
  type: EDIT_CHEMICAL_SUCCESSFUL,
});

export const editChemicalEpic = (action$) =>
  action$.pipe(
    ofType(EDIT_CHEMICAL),
    switchMap((action: any) => from(updateChemical(action.payload)))
  );

export const refreshChemicalEpic = (action$, $state) =>
  action$.pipe(
    ofType(EDIT_CHEMICAL_SUCCESSFUL),
    map((action: any) => fetchChemical($state.value.currentChemical.id))
  );
