import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  InputGroup,
  Alert,
} from "reactstrap";
import "./Login.css";
import { useDispatch, useSelector } from "react-redux";
import { login } from "./effects";

const LoginView = () => {
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const loginError = useSelector<any>((state) => state.auth.error);

  const dispatch = useDispatch();

  const loginFn = React.useCallback(
    () => dispatch(login(userEmail, userPassword)),
    [dispatch, userEmail, userPassword]
  );

  return (
    <div className="minh-100 d-flex align-items-center bg-auth">
      <Container>
        <Row className="justify-content-center">
          <div className="col-12 col-md-5 col-xl-4 my-5">
            <h1 className="display-4 text-center mb-3">Sign in</h1>

            <p className="text-muted text-center mb-5">
              Access to your dashboard.
            </p>
            <Alert color="danger" isOpen={!!loginError}>
              {loginError}
            </Alert>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                loginFn();
              }}
            >
              <div className="form-group">
                <label>Email Address</label>

                <Input
                  value={userEmail}
                  type="email"
                  required
                  placeholder="name@address.com"
                  onChange={(e) => setUserEmail(e.target.value)}
                />
              </div>

              <div className="form-group">
                <Row>
                  <Col>
                    <label>Password</label>
                  </Col>
                </Row>

                <InputGroup className="input-group input-group-merge">
                  <Input
                    value={userPassword}
                    type="password"
                    required
                    placeholder="Enter your password"
                    onChange={(e) => setUserPassword(e.target.value)}
                  />
                </InputGroup>
              </div>

              <Button
                type="submit"
                className="btn btn-lg btn-block btn-primary mb-3"
              >
                Sign in
              </Button>
            </form>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export const Login = () => <LoginView />;
