import * as React from "react";
import { DefaultLayout } from "../_components/DefaultLayout";
import { Header } from "../_components/Header";
import { Button, Progress } from "reactstrap";
import { fetchLocation, fetchLocationContainers } from "./effects";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { LocationEdit } from "../LocationEdit/LocationEdit";
import { push } from "connected-react-router";
import MapGL, { Marker } from "react-map-gl";
import MARKER_STYLE from "./marker-style";
import { AddonConversionRate } from "../Graphs/AddonConversionRate";
import { AverageCostPerWash } from "../Graphs/AverageCostPerWash";
import { TotalWashes } from "../Graphs/TotalWashes";
import { ChemicalNeeded } from "../Graphs/ChemicalNeeded";

type viewportState = {
  latitude: number;
  longitude: number;
  zoom: number;
  bearing: number;
  pitch: number;
};

const ListItem = (props) => {
  return (
    <tr onClick={props.onClick}>
      <td>{props.chemicalName}</td>
      <td>
        <Progress
          color="success"
          className="progress-sm"
          style={{ width: "70%" }}
          value={props.currentLevel}
        />
      </td>
      <td>
        <div className="badge badge-soft-success">{props.status}</div>
      </td>
    </tr>
  );
};

const LocationDetailsView = () => {
  const [modal, setModal] = React.useState(false);
  const [viewport, setViewport] = React.useState<viewportState>();
  let { id } = useParams();

  const location: any = useSelector<any>((state) => state.currentLocation);

  const containers: any = useSelector<any>((state) => state.locationContainers);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchLocation(id));
    dispatch(fetchLocationContainers(id));
  }, [id]);

  React.useEffect(() => {
    setViewport({
      latitude: location.lat,
      longitude: location.lon,
      zoom: 14,
      bearing: 0,
      pitch: 0,
    });
  }, [location]);

  const toggle = () => setModal(!modal);

  const viewDetail = React.useCallback(
    (id) => dispatch(push(`/containers/${id}`)),
    [dispatch]
  );
  return (
    <DefaultLayout>
      <div className="col-12">
        <Header title={`${location?.name ?? ""}`} pretitle="Location">
          <Button className="lift" onClick={toggle}>
            Edit Location
          </Button>
        </Header>
        <div className="row">
          <div className="col-8">
            <div className="row">
              <div className="col-4">
                <AddonConversionRate rate={5.7} />
              </div>
              <div className="col-4">
                <AverageCostPerWash cost={1.18} />
              </div>

              <div className="col-4">
                <TotalWashes total={437} />
              </div>
              <div className="col-7">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-header-title">Containers</h4>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-sm table-nowrap card-table table-hover">
                        <thead>
                          <tr>
                            <th>
                              <a href="#" className="text-muted sort">
                                Chemical
                              </a>
                            </th>
                            <th>
                              <a href="#" className="text-muted sort">
                                Current Level
                              </a>
                            </th>
                            <th>
                              <a href="#" className="text-muted sort">
                                Status
                              </a>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list">
                          {containers.rows
                            .sort(
                              (a, b) =>
                                a?.container_level?.percentage -
                                b?.container_level?.percentage
                            )
                            .map((item) => (
                              <ListItem
                                key={item.id}
                                currentLevel={item?.container_level?.percentage}
                                chemicalName={item?.chemical?.name}
                                status={item?.deletedAt ? "Inactive" : "Active"}
                                onClick={() => viewDetail(item.id)}
                              />
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-5">
                <ChemicalNeeded />
              </div>
            </div>
          </div>
          <div className="col-12 col-xl-4">
            <div className="card">
              <div className="card-body">
                <div className="list-group list-group-flush mt-n3">
                  <div className="list-group-item">
                    <div className="row align-items-center">
                      <div className="col">
                        <h5 className="mb-0">Name</h5>
                      </div>
                      <div className="col-auto">{location?.name}</div>
                    </div>
                  </div>
                  <div className="list-group-item">
                    <div className="row align-items-center">
                      <div className="col">
                        <h5 className="mb-0">Customer</h5>
                      </div>
                      <div className="col-auto">
                        <Link
                          to={`/customers/${location?.customer?.id}`}
                          className="nav-link p-0"
                        >
                          {location?.customer?.name}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="list-group-item">
                    <div className="row align-items-center">
                      <div className="col">
                        <h5 className="mb-0">Address</h5>
                      </div>
                      <div className="col-auto">
                        {location.street1} {location.street2} {location.city},{" "}
                        {location.state} {location.zip}
                      </div>
                    </div>
                  </div>
                </div>
                <MapGL
                  {...viewport}
                  width="100%"
                  height="300px"
                  mapStyle="mapbox://styles/mapbox/light-v9"
                  mapboxApiAccessToken={
                    "pk.eyJ1Ijoic3V0cmFpbm5vdmF0aW9ucyIsImEiOiJja2diYnk5dTgwZmd5MnhyeXYwcjl5czhjIn0.p2UCmgXjpqIc8XRRtqDcDA"
                  }
                >
                  <style>{MARKER_STYLE}</style>
                  {location.lon ? (
                    <Marker
                      longitude={location.lon}
                      latitude={location.lat}
                      captureDrag={false}
                      captureDoubleClick={false}
                    >
                      <div className="location">
                        <span>{name}</span>
                      </div>
                    </Marker>
                  ) : null}
                </MapGL>
              </div>
            </div>
          </div>
        </div>

        <LocationEdit isOpen={modal} toggle={toggle} />
      </div>
    </DefaultLayout>
  );
};

export const LocationDetails = () => <LocationDetailsView />;
