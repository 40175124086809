import { switchMap } from "rxjs/operators";
import { from } from "rxjs";
import { ofType } from "redux-observable";
import API from "../api";

export const FETCH_DEVICES = "FETCH_DEVICES";
export const FETCH_DEVICES_SUCCESSFUL = "FETCH_DEVICES_SUCCESSFUL";

const getDevices = () =>
  API.get("devices")
    .json<any>()
    .then((devices = []) => fetchDevicesSuccessful(devices));

export const fetchDevices = () => ({
  type: FETCH_DEVICES,
});

export const fetchDevicesSuccessful = (devices: any) => ({
  type: FETCH_DEVICES_SUCCESSFUL,
  payload: devices,
});

export const fetchDevicesEpic = (action$) =>
  action$.pipe(
    ofType(FETCH_DEVICES),
    switchMap((action: any) => from(getDevices()))
  );

export const devicesReducer = (state = { rows: [] }, action: any) => {
  switch (action.type) {
    case FETCH_DEVICES_SUCCESSFUL:
      return action.payload;
    default:
      return state;
  }
};
