import { map, switchMap, tap } from "rxjs/operators";
import { from } from "rxjs";
import { ofType } from "redux-observable";
import API from "../api";

export const FETCH_LOCATION = "FETCH_LOCATION";
export const FETCH_LOCATION_SUCCESSFUL = "FETCH_LOCATION_SUCCESSFUL";

export const FETCH_LOCATION_CONTAINERS = "FETCH_LOCATION_CONTAINERS";
export const FETCH_LOCATION_CONTAINERS_SUCCESSFUL =
  "FETCH_LOCATION_CONTAINERS_SUCCESSFUL";

const getLocation = (id) =>
  API.get(`locations/${id}`)
    .json<any>()
    .then((location = {}) => fetchLocationSuccessful(location));

const getLocationContainers = (id) =>
  API.get(`locations/${id}/containers`, {
    searchParams: {
      limit: 1000,
    },
  })
    .json<any>()
    .then((containers) => fetchLocationContainersSuccessful(containers));

export const fetchLocation = (id) => ({
  type: FETCH_LOCATION,
  payload: id,
});

export const fetchLocationSuccessful = (location: any) => ({
  type: FETCH_LOCATION_SUCCESSFUL,
  payload: location,
});

export const fetchLocationContainers = (id) => ({
  type: FETCH_LOCATION_CONTAINERS,
  payload: id,
});

export const fetchLocationContainersSuccessful = (location: any) => ({
  type: FETCH_LOCATION_CONTAINERS_SUCCESSFUL,
  payload: location,
});

export const fetchLocationEpic = (action$) =>
  action$.pipe(
    ofType(FETCH_LOCATION),
    switchMap((action: any) => from(getLocation(action.payload)))
  );

export const fetchLocationContainersEpic = (action$) =>
  action$.pipe(
    ofType(FETCH_LOCATION_CONTAINERS),
    switchMap((action: any) => from(getLocationContainers(action.payload)))
  );

export const locationReducer = (state = {}, action: any) => {
  switch (action.type) {
    case FETCH_LOCATION_SUCCESSFUL:
      return action.payload;
    default:
      return state;
  }
};

export const locationContainersReducer = (
  state = { rows: [] },
  action: any
) => {
  switch (action.type) {
    case FETCH_LOCATION_CONTAINERS_SUCCESSFUL:
      return action.payload;
    default:
      return state;
  }
};
