import ky from "ky";
import fb from "./firebase";

const API: typeof ky = ky.extend({
  prefixUrl: "https://api.sutrainnovations.com",
  // prefixUrl: "http://localhost:5000",
  hooks: {
    beforeRequest: [
      async (request) => {
        const token = await fb.auth().currentUser.getIdToken();
        request.headers.set("Authorization", `Bearer ${token}`);
      },
    ],
  },
});

export default API;
