import * as React from "react";
import { DefaultLayout } from "../_components/DefaultLayout";
import { Header } from "../_components/Header";
import {
  Progress,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  CardHeader,
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Col,
  Input,
} from "reactstrap";
import { fetchChemical, fetchChemicalContainers } from "./effects";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { useParams } from "react-router-dom";
import { ChemicalEdit } from "../ChemicalEdit/ChemicalEdit";

const ListItem = (props) => {
  return (
    <tr onClick={props.onClick}>
      <td>{props.locationName}</td>
      <td>
        <Progress
          color="success"
          className="progress-sm"
          style={{ width: "70%" }}
          value={props.currentLevel}
        />
      </td>
      <td>{props.containerSize}</td>
      <td>
        <div className="badge badge-soft-success">{props.status}</div>
      </td>
      <td className="text-right">
        <div className="dropdown">
          <a href="#" className="dropdown-ellipses dropdown-toggle">
            <i className="fe fe-more-vertical"></i>
          </a>
        </div>
      </td>
    </tr>
  );
};

const ChemicalDetailsView = () => {
  const [modal, setModal] = React.useState(false);
  let { id } = useParams<{ id: any }>();

  const chemical: any = useSelector<any>((state) => state.currentChemical);
  const containers: any = useSelector<any>((state) => state.chemicalContainers);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchChemical(id));
    dispatch(fetchChemicalContainers(id));
  }, [id]);

  const toggle = () => setModal(!modal);

  const viewDetail = React.useCallback(
    (id) => dispatch(push(`/containers/${id}`)),
    [dispatch]
  );

  return (
    <DefaultLayout>
      {" "}
      <div className="col-12">
        <Header title={`${chemical?.name ?? " "}`} pretitle="Chemical">
          <Button className="lift" onClick={toggle}>
            Edit Chemical
          </Button>
        </Header>

        <div className="row">
          <div className="col-12 col-xl-8">
            <div className="card">
              <div className="card-header">
                <h4 className="card-header-title">Containers</h4>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-sm table-nowrap card-table table-hover">
                    <thead>
                      <tr>
                        <th>
                          <a href="#" className="text-muted sort">
                            Location
                          </a>
                        </th>
                        <th>
                          <a href="#" className="text-muted sort">
                            Current Level
                          </a>
                        </th>
                        <th>
                          <a href="#" className="text-muted sort">
                            Container Size
                          </a>
                        </th>
                        <th colSpan={2}>
                          <a href="#" className="text-muted sort">
                            Status
                          </a>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="list">
                      {containers.rows.map((item) => (
                        <ListItem
                          key={item.id}
                          containerSize={item?.container_size?.name}
                          currentLevel={item?.container_level?.percentage}
                          chemicalName={item?.chemical?.name}
                          locationName={item?.location?.name}
                          status={item?.deletedAt ? "Inactive" : "Active"}
                          onClick={() => viewDetail(item.id)}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-xl-4">
            <div className="card">
              <div className="card-body">
                <div className="list-group list-group-flush my-n3">
                  <div className="list-group-item">
                    <div className="row align-items-center">
                      <div className="col">
                        <h5 className="mb-0">Name</h5>
                      </div>
                      <div className="col-auto">{chemical?.name}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ChemicalEdit isOpen={modal} toggle={toggle} />
      </div>
    </DefaultLayout>
  );
};

export const ChemicalDetails = () => <ChemicalDetailsView />;
