import * as React from "react";

export const Header = props => {
  return (
    <div className="header">
      <div className="header-body">
        <div className="row align-items-end">
          <div className="col">
            <h6 className="header-pretitle">{props.pretitle}</h6>

            <h1 className="header-title">{props.title}</h1>
          </div>
          <div className="col-auto">{props.children}</div>
        </div>
      </div>
    </div>
  );
};
