export default `
.location:before {
  content: ' ';
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #000;
  border-radius: 8px;
  margin: 0 8px;
}
.location {
  border-radius: 20px;
  margin: -12px;
  color: transparent;
  line-height: 24px;
  font-size: 13px;
  white-space: nowrap;
}
.location span {
  display: none;
}
.location:hover {
  background: rgba(0,0,0,0.8);
  color: #fff;
}
.location:hover::before {
  background: #FFF;
}
.location:hover span {
  display: inline-block;
}
.mapboxgl-marker:hover {
  z-index: 1000;
}
`;
