import { map, switchMap, tap } from "rxjs/operators";
import { from } from "rxjs";
import { ofType } from "redux-observable";
import API from "../api";
import { fetchLocations } from "../LocationList/effects";

export const CREATE_LOCATION = "CREATE_LOCATION";
export const CREATE_LOCATION_SUCCESSFUL = "CREATE_LOCATION_SUCCESSFUL";

const addLocation = (location) =>
  API.post("locations", { json: location })
    .json<any>()
    .then((locations = []) => createLocationSuccessful());

export const createLocation = (location) => ({
  type: CREATE_LOCATION,
  payload: location,
});

export const createLocationSuccessful = () => ({
  type: CREATE_LOCATION_SUCCESSFUL,
});

export const createLocationEpic = (action$) =>
  action$.pipe(
    ofType(CREATE_LOCATION),
    switchMap((action: any) => from(addLocation(action.payload)))
  );

export const refreshLocationsEpic = (action$) =>
  action$.pipe(
    ofType(CREATE_LOCATION_SUCCESSFUL),
    map((action) => fetchLocations())
  );
