import * as React from "react";
import Fuse from "fuse.js";
import { useSelector, useDispatch } from "react-redux";
import { fetchUsers } from "./effects";
import { push } from "connected-react-router";
import { DefaultLayout } from "../_components/DefaultLayout";
import { Header } from "../_components/Header";
import { UserCreate } from "../UserCreate/UserCreate";
import {
  useQueryParam,
  StringParam,
  withDefault,
  NumberParam,
  BooleanParam,
} from "use-query-params";
import { BasePagination } from "../_components/BasePagination";
import { useDebounce } from "use-debounce";
import { Spinner } from "reactstrap";

const ListItem = (props) => {
  return (
    <tr onClick={props.onClick}>
      <td>{props.name}</td>
      <td>{props.email}</td>
      <td>
        <div className="badge badge-soft-success">{props.status}</div>
      </td>
      <td className="text-right">
        <div className="dropdown">
          <a href="#" className="dropdown-ellipses dropdown-toggle">
            <i className="fe fe-more-vertical"></i>
          </a>
        </div>
      </td>
    </tr>
  );
};

const UserListView = () => {
  const [modal, setModal] = React.useState(false);
  const [searchTerm, setSearchTerm] = useQueryParam(
    "q",
    withDefault(StringParam, "")
  );
  const [searchTermDebounced] = useDebounce(searchTerm, 1000);
  const [sort, setSort] = useQueryParam(
    "sort",
    withDefault(StringParam, "firstName")
  );
  const [page, setPage] = useQueryParam("page", withDefault(NumberParam, 1));
  const [sortReverse, setSortReverse] = useQueryParam(
    "sortReverse",
    withDefault(BooleanParam, false)
  );
  const users: any = useSelector<any>((state) => state.users);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      fetchUsers({
        page,
        sort,
        sortReverse,
        q: searchTermDebounced,
      })
    );
  }, [dispatch, page, sort, sortReverse, searchTermDebounced]);

  const viewDetail = React.useCallback((id) => dispatch(push(`/users/${id}`)), [
    dispatch,
  ]);

  const toggle = () => setModal(!modal);

  const maxPages = Math.floor(users.count / 10) + 1;
  return (
    <DefaultLayout>
      {" "}
      <div className="col-12">
        <Header title="Users" pretitle="Overview">
          {users.loading || (
            <a href="#!" className="btn btn-primary lift" onClick={toggle}>
              Add User
            </a>
          )}
        </Header>
        {users.loading ? (
          <div className="text-center mt-6">
            <Spinner size="xl" />
          </div>
        ) : (
          <React.Fragment>
            <div className="card">
              <div className="card-header">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="input-group input-group-flush">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fe fe-search"></i>
                      </span>
                    </div>
                    <input
                      className="form-control search"
                      type="search"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </form>
              </div>
              <div className="table-responsive">
                <table className="table table-sm table-nowrap card-table table-hover">
                  <thead>
                    <tr>
                      <th>
                        <a
                          data-sort="tables-row"
                          onClick={() => setSort("firstName")}
                          className="text-muted sort"
                        >
                          Name
                        </a>
                      </th>
                      <th>
                        <a
                          data-sort="tables-row"
                          onClick={() => setSort("email")}
                          className="text-muted sort"
                        >
                          Email
                        </a>
                      </th>
                      <th colSpan={2}>
                        <a
                          data-sort="tables-row"
                          onClick={() => setSort("status")}
                          className="text-muted sort"
                        >
                          Status
                        </a>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {users.rows.map((item) => (
                      <ListItem
                        key={item.id}
                        name={`${item.firstName} ${item.lastName}`}
                        email={item.email}
                        status={item.deletedAt ? "Inactive" : "Active"}
                        onClick={() => viewDetail(item.id)}
                      />
                    ))}
                  </tbody>
                </table>
                <BasePagination
                  page={page}
                  maxPages={maxPages}
                  setPage={setPage}
                />
              </div>
            </div>

            <UserCreate isOpen={modal} toggle={toggle} />
          </React.Fragment>
        )}
      </div>
    </DefaultLayout>
  );
};

export const UserList = () => <UserListView />;
