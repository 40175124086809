import * as React from "react";
import {
  Modal,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Col,
  Input,
  Badge,
} from "reactstrap";
import { useDispatch } from "react-redux";
import { createContainerSize } from "./effects";

export const ContainerSizeCreate = (props) => {
  const [name, setName] = React.useState("");
  const [volume, setVolume] = React.useState("");
  const [height, setHeight] = React.useState("");
  const [capOffset, setCapOffset] = React.useState("");
  const dispatch = useDispatch();

  const addContainerSize = React.useCallback(() => {
    props.toggle();
    return dispatch(
      createContainerSize({
        name,
        volume,
        height,
        capOffset,
      })
    );
  }, [dispatch, name, volume, height, capOffset]);

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle}>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          addContainerSize();
        }}
      >
        <Card className="modal-card">
          <CardHeader>
            <h4 className="card-header-title">Add Container Size</h4>
            <Button close className="float-right" onClick={props.toggle} />
          </CardHeader>
          <CardBody>
            <FormGroup row>
              <Label for="exampleSelect" sm={3}>
                Name
              </Label>
              <Col sm={9}>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="exampleSelect" sm={3}>
                Volume
                <Badge color="secondary" className="ml-1">
                  ml
                </Badge>
              </Label>
              <Col sm={9}>
                <Input
                  type="number"
                  name="volume"
                  id="volume"
                  value={volume}
                  onChange={(e) => setVolume(e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="exampleSelect" sm={3}>
                Height
                <Badge color="secondary" className="ml-1">
                  mm
                </Badge>
              </Label>
              <Col sm={9}>
                <Input
                  type="number"
                  name="height"
                  id="height"
                  value={height}
                  onChange={(e) => setHeight(e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="exampleSelect" sm={3}>
                Cap Offset
                <Badge color="secondary" className="ml-1">
                  mm
                </Badge>
              </Label>
              <Col sm={9}>
                <Input
                  type="number"
                  name="capOffset"
                  id="capOffset"
                  value={capOffset}
                  onChange={(e) => setCapOffset(e.target.value)}
                />
              </Col>
            </FormGroup>
          </CardBody>
          <CardFooter>
            <Button
              color="success"
              className="float-right ml-2"
              onClick={addContainerSize}
            >
              Create
            </Button>
            <Button outline className="float-right" onClick={props.toggle}>
              Cancel
            </Button>
          </CardFooter>
        </Card>
      </Form>
    </Modal>
  );
};
