import { map, switchMap, tap } from "rxjs/operators";
import { from } from "rxjs";
import { ofType } from "redux-observable";
import API from "../api";

type PurchaseProps = {
  client?: string;
  chemicalName?: string;
  containerSize?: string;
};
export const PURCHASE = "PURCHASE";
export const PURCHASE_SUCCESSFUL = "PURCHASE_SUCCESSFUL";
const sendPurchaseEmail = ({
  client,
  chemicalName,
  containerSize,
}: PurchaseProps) =>
  // TODO : Create a real endpoint, and don't pass in these values from client side
  fetch(
    `https://us-central1-clean-authority-241200.cloudfunctions.net/sendChemicalPurchaseHTTP?to=levi@sutrainnovations.com&chemical=${chemicalName}&containerSize=${containerSize}&client=${client}`
  )
    .then((value) => value.text())
    .then(() => purchaseSuccessful());

export const purchase = ({
  client,
  chemicalName,
  containerSize,
}: PurchaseProps) => ({
  type: PURCHASE,
  payload: {
    client,
    chemicalName,
    containerSize,
  },
});
export const purchaseSuccessful = () => ({
  type: PURCHASE_SUCCESSFUL,
});

export const purchaseEpic = (action$) =>
  action$.pipe(
    ofType(PURCHASE),
    switchMap((action: any) => from(sendPurchaseEmail(action.payload)))
  );

export const FETCH_CONTAINER = "FETCH_CONTAINER";
export const FETCH_CONTAINER_SUCCESSFUL = "FETCH_CONTAINER_SUCCESSFUL";
export const FETCH_CONTAINER_LEVELS = "FETCH_CONTAINER_LEVELS";
export const FETCH_CONTAINER_LEVELS_SUCCESSFUL =
  "FETCH_CONTAINER_LEVELS_SUCCESSFUL";

const getContainer = (id) =>
  API.get(`containers/${id}`)
    .json<any>()
    .then((container = {}) => fetchContainerSuccessful(container));

const getContainerLevels = (id) =>
  API.get(`containers/${id}/levels`, {
    searchParams: {
      limit: 100,
      sort: "timestamp",
      sortReverse: true,
    },
  })
    .json<any>()
    .then((containerLevels = {}) =>
      fetchContainerLevelsSuccessful(containerLevels)
    );

export const fetchContainer = (id) => ({
  type: FETCH_CONTAINER,
  payload: id,
});

export const fetchContainerSuccessful = (container: any) => ({
  type: FETCH_CONTAINER_SUCCESSFUL,
  payload: container,
});

export const fetchContainerLevels = (id) => ({
  type: FETCH_CONTAINER_LEVELS,
  payload: id,
});

export const fetchContainerLevelsSuccessful = (container: any) => ({
  type: FETCH_CONTAINER_LEVELS_SUCCESSFUL,
  payload: container,
});

export const fetchContainerEpic = (action$) =>
  action$.pipe(
    ofType(FETCH_CONTAINER),
    switchMap((action: any) => from(getContainer(action.payload)))
  );

export const fetchContainerLevelsEpic = (action$) =>
  action$.pipe(
    ofType(FETCH_CONTAINER_LEVELS),
    switchMap((action: any) => from(getContainerLevels(action.payload)))
  );

export const containerReducer = (state = { loading: true }, action: any) => {
  switch (action.type) {
    case FETCH_CONTAINER_SUCCESSFUL:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case FETCH_CONTAINER:
      return {
        ...state,
        levels: undefined,
        loading: true,
      };
    case FETCH_CONTAINER_LEVELS_SUCCESSFUL:
      return {
        ...state,
        levels: action.payload.map((row) => ({
          ...row,
          timestamp: new Date(row.timestamp),
        })),
      };
    default:
      return state;
  }
};
