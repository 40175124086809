import React from "react";
import { Badge } from "reactstrap";

export const CostPerChemical = () => (
  <div className="card card-adjust" style={{ height: 350 }}>
    <div className="card-header">
      <h4 className="card-header-title">Chemicals Costs</h4>
    </div>
    <div className="card-body">
      <div className="table-responsive">
        <table className="table table-sm table-nowrap card-table table-hover">
          <thead>
            <tr>
              <th>
                <a href="#" className="text-muted sort">
                  Chemical
                </a>
              </th>
              <th>
                <a href="#" className="text-muted sort">
                  Cost
                </a>
              </th>
            </tr>
          </thead>
          <tbody className="list">
            <tr>
              <td>TRU200 - True Pearl Dry</td>
              <td>
                $0.17
                <Badge color="warning" className="ml-2">
                  +/- 3%
                </Badge>
              </td>
            </tr>
            <tr>
              <td>EXT138 - Wheel Blaster</td>
              <td>
                $0.15
                <Badge color="warning" className="ml-2">
                  +/- 4%
                </Badge>
              </td>
            </tr>
            <tr>
              <td>EXT280Y - Foam Aroma Yellow</td>
              <td>
                $0.11
                <Badge color="warning" className="ml-2">
                  +/- 2%
                </Badge>
              </td>
            </tr>
            <tr>
              <td>Dawn & Vinegar</td>
              <td>
                $0.06
                <Badge color="warning" className="ml-2">
                  +/- 5%
                </Badge>
              </td>
            </tr>
            <tr>
              <td>EXT280B - Foam Aroma Blue</td>
              <td>
                $0.09
                <Badge color="warning" className="ml-2">
                  +/- 5%
                </Badge>
              </td>
            </tr>
            <tr>
              <td>EXT350 - Super Foam</td>
              <td>
                $0.11
                <Badge color="warning" className="ml-2">
                  +/- 4%
                </Badge>
              </td>
            </tr>
            <tr>
              <td>EXT137 - Magnum Wheel Cleaner</td>
              <td>
                $0.14
                <Badge color="warning" className="ml-2">
                  +/- 7%
                </Badge>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
);
