import React from "react";
import { Route, Switch, Redirect } from "react-router";

import { Dashboard } from "./Dashboard/Dashboard";
import { Login } from "./Auth/Login";
import { CustomerList } from "./CustomerList/CustomerList";
import { LocationList } from "./LocationList/LocationList";
import { ContainerList } from "./ContainerList/ContainerList";
import { UserList } from "./UserList/UserList";
import { ContainerSizeList } from "./ContainerSizeList/ContainerSizeList";
import { ChemicalList } from "./ChemicalList/ChemicalList";
import { ContainerDetails } from "./ContainerDetails/ContainerDetails";
import { ChemicalDetails } from "./ChemicalDetails/ChemicalDetails";
import { ContainerSizeDetails } from "./ContainerSizeDetails/ContainerSizeDetails";
import { CustomerDetails } from "./CustomerDetails/CustomerDetails";
import { LocationDetails } from "./LocationDetails/LocationDetails";
import { UserDetails } from "./UserDetails/UserDetails";

export const Routes = () => {
  return (
    <>
      <Switch>
        <Route exact path="/" render={Dashboard} />
        <Route exact path="/customers" render={CustomerList} />
        <Route exact path="/customers/:id" render={CustomerDetails} />
        <Route exact path="/locations" render={LocationList} />
        <Route exact path="/locations/:id" render={LocationDetails} />
        <Route exact path="/chemicals" render={ChemicalList} />
        <Route exact path="/chemicals/:id" render={ChemicalDetails} />
        <Route exact path="/containers" render={ContainerList} />
        <Route exact path="/containers/:id" render={ContainerDetails} />
        <Route exact path="/sizes" render={ContainerSizeList} />
        <Route exact path="/sizes/:id" render={ContainerSizeDetails} />
        <Route exact path="/users" render={UserList} />
        <Route exact path="/users/:id" render={UserDetails} />
        <Route exact path="/search" render={Dashboard} />
        <Route exact path="/settings" render={Dashboard} />
        <Route exact path="/login" render={Login} />
        <Redirect to="/" />
      </Switch>
    </>
  );
};
