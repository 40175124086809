import * as React from "react";
import {
  Modal,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Col,
  Input,
  Row,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import states from "states-us";

import { createLocation } from "./effects";
import { fetchCustomers } from "../CustomerList/effects";

export const LocationCreate = (props) => {
  const [customerId, setCustomerId] = React.useState("");
  const [name, setName] = React.useState("");
  const [street1, setStreet1] = React.useState("");
  const [street2, setStreet2] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [zip, setZip] = React.useState("");
  const [lat, setLat] = React.useState(0);
  const [lon, setLon] = React.useState(0);
  const customers: any = useSelector<any>((state) => state.customers);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      fetchCustomers({
        limit: 1000,
      })
    );
  }, []);

  const addLocation = React.useCallback(() => {
    props.toggle();
    return dispatch(
      createLocation({
        name,
        street1,
        street2,
        city,
        state,
        zip,
        lat,
        lon,
        customerId,
      })
    );
  }, [
    dispatch,
    name,
    street1,
    street2,
    city,
    state,
    zip,
    lat,
    lon,
    customerId,
  ]);

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle}>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          addLocation();
        }}
      >
        <Card className="modal-card">
          <CardHeader>
            <h4 className="card-header-title">Add Location</h4>
            <Button close className="float-right" onClick={props.toggle} />
          </CardHeader>
          <CardBody>
            <FormGroup>
              <Label for="state">Customer</Label>
              <Input
                type="select"
                name="select"
                id="state"
                value={customerId}
                onChange={(e) => setCustomerId(e.target.value)}
              >
                <option>Select Customer</option>
                {customers.rows.map((customer) => (
                  <option key={customer.id} value={customer.id}>
                    {customer.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="locationName">Name</Label>
              <Input
                type="text"
                name="name"
                id="locationName"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="street1">Street 1</Label>
              <Input
                type="text"
                name="name"
                id="street1"
                value={street1}
                onChange={(e) => setStreet1(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="street2">Street 2</Label>
              <Input
                type="text"
                name="name"
                id="street2"
                value={street2}
                onChange={(e) => setStreet2(e.target.value)}
              />
            </FormGroup>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="city">City</Label>
                  <Input
                    type="text"
                    name="name"
                    id="city"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="state">State</Label>
                  <Input
                    type="select"
                    name="select"
                    id="state"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  >
                    <option>Select State</option>
                    {states.map((state) => (
                      <option
                        key={state.abbreviation}
                        value={state.abbreviation}
                      >
                        {state.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Label for="zip">ZIP Code</Label>
              <Input
                type="number"
                name="name"
                id="zip"
                value={zip}
                onChange={(e) => setZip(e.target.value)}
              />
            </FormGroup>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="lat">Latitude</Label>
                  <Input
                    type="number"
                    name="name"
                    id="lat"
                    value={lat}
                    onChange={(e) => setLat(parseFloat(e.target.value || "0"))}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="lon">Longitude</Label>
                  <Input
                    type="number"
                    name="name"
                    id="lon"
                    value={lon}
                    onChange={(e) => setLon(parseFloat(e.target.value || "0"))}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button
              color="success"
              className="float-right ml-2"
              onClick={addLocation}
            >
              Create
            </Button>
            <Button outline className="float-right" onClick={props.toggle}>
              Cancel
            </Button>
          </CardFooter>
        </Card>
      </Form>
    </Modal>
  );
};
