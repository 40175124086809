import * as React from "react";
import { DefaultLayout } from "../_components/DefaultLayout";
import { Header } from "../_components/Header";
import {
  useQueryParam,
  NumberParam,
  StringParam,
  BooleanParam,
  withDefault,
} from "use-query-params";
import {
  Progress,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  CardHeader,
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Col,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Spinner,
} from "reactstrap";
import { ContainerCreate } from "../ContainerCreate/ContainerCreate";
import { fetchContainers } from "./effects";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { useParams } from "react-router";
import { useDebounce } from "use-debounce";
import { BasePagination } from "../_components/BasePagination";

const ListItem = (props) => {
  return (
    <tr onClick={props.onClick}>
      <td>{props.locationName}</td>
      <td>{props.chemicalName}</td>
      <td>
        <Progress
          color="success"
          className="progress-sm"
          style={{ width: "70%" }}
          value={props.currentLevel}
        />
      </td>
      <td>{props.containerSize}</td>
      <td>
        <div className="badge badge-soft-success">{props.status}</div>
      </td>
      <td className="text-right">
        <div className="dropdown">
          <a href="#" className="dropdown-ellipses dropdown-toggle">
            <i className="fe fe-more-vertical"></i>
          </a>
        </div>
      </td>
    </tr>
  );
};

const ContainerListView = () => {
  const [modal, setModal] = React.useState(false);
  const [searchTerm, setSearchTerm] = useQueryParam(
    "q",
    withDefault(StringParam, "")
  );
  const [searchTermDebounced] = useDebounce(searchTerm, 1000);
  const [sort, setSort] = useQueryParam(
    "sort",
    withDefault(StringParam, "$location.name$")
  );
  const [page, setPage] = useQueryParam("page", withDefault(NumberParam, 1));
  const [sortReverse, setSortReverse] = useQueryParam(
    "sortReverse",
    withDefault(BooleanParam, false)
  );
  const containers: any = useSelector<any>((state) => state.containers);
  const query = useParams();

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchContainers(page, sort, sortReverse, searchTermDebounced));
  }, [dispatch, page, sort, sortReverse, searchTermDebounced]);

  const viewDetail = React.useCallback(
    (id) => dispatch(push(`/containers/${id}`)),
    [dispatch]
  );

  const updateSort = React.useCallback(
    (id) => dispatch(push(`/containers/${id}`)),
    [dispatch]
  );

  const toggle = () => setModal(!modal);

  const maxPages = Math.floor(containers.count / 10) + 1;
  console.log(maxPages);
  return (
    <DefaultLayout>
      {" "}
      <div className="col-12">
        <Header title="Containers" pretitle="Overview">
          {containers.loading || (
            <Button className="lift" onClick={toggle}>
              Add Container
            </Button>
          )}
        </Header>
        {containers.loading ? (
          <div className="text-center mt-6">
            <Spinner size="xl" />
          </div>
        ) : (
          <React.Fragment>
            <div className="card">
              <div className="card-header">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="input-group input-group-flush">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fe fe-search"></i>
                      </span>
                    </div>
                    <input
                      className="form-control search"
                      type="text"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </form>
              </div>
              <div className="table-responsive">
                <table className="table table-sm table-nowrap card-table table-hover">
                  <thead>
                    <tr>
                      <th>
                        <a
                          data-sort="tables-row"
                          onClick={() => setSort("$location.name$")}
                          className="text-muted sort"
                        >
                          Location
                        </a>
                      </th>
                      <th>
                        <a
                          data-sort="tables-row"
                          onClick={() => setSort("$chemical.name$")}
                          className="text-muted sort"
                        >
                          Chemical
                        </a>
                      </th>
                      <th>
                        <a
                          data-sort="tables-row"
                          onClick={() => setSort("$container_level.distance$")}
                          className="text-muted sort"
                        >
                          Current Level
                        </a>
                      </th>
                      <th>
                        <a
                          data-sort="tables-row"
                          onClick={() => setSort("$container_size.name$")}
                          className="text-muted sort"
                        >
                          Container Size
                        </a>
                      </th>
                      <th colSpan={2}>
                        <a
                          data-sort="tables-row"
                          onClick={() => setSort("status")}
                          className="text-muted sort"
                        >
                          Status
                        </a>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {containers.rows.map((item) => (
                      <ListItem
                        key={item.id}
                        containerSize={item.container_size.name}
                        currentLevel={item.container_level.percentage}
                        chemicalName={item.chemical.name}
                        locationName={item.location.name}
                        status={item.deletedAt ? "Inactive" : "Active"}
                        onClick={() => viewDetail(item.id)}
                      />
                    ))}
                  </tbody>
                </table>
                <BasePagination
                  page={page}
                  maxPages={maxPages}
                  setPage={setPage}
                />
              </div>
            </div>

            <ContainerCreate isOpen={modal} toggle={toggle} />
          </React.Fragment>
        )}
      </div>
    </DefaultLayout>
  );
};

export const ContainerList = () => <ContainerListView />;
