import React from "react";

export const ChemicalNeededByLocation = () => (
  <div className="card card-adjust" style={{ height: 350 }}>
    <div className="card-header">
      <h4 className="card-header-title">Chemicals Needed</h4>
    </div>
    <div className="card-body">
      <div className="table-responsive">
        <table className="table table-sm table-nowrap card-table table-hover">
          <thead>
            <tr>
              <th>
                <a href="#" className="text-muted sort">
                  Chemical
                </a>
              </th>
              <th>
                <a href="#" className="text-muted sort">
                  Estimated Date
                </a>
              </th>
              <th>
                <a href="#" className="text-muted sort">
                  Location
                </a>
              </th>
            </tr>
          </thead>
          <tbody className="list">
            <tr>
              <td>Dawn & Vinegar</td>
              <td>Jul 12th</td>
              <td>Bubbly Suds Express Wash</td>
            </tr>
            <tr>
              <td>EXT138 - Wheel Blaster</td>
              <td>Jul 23th</td>
              <td>Bubbly Suds Express Wash</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
);
