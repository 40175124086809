import * as React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";

import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/theme.min.css";
import "../assets/fonts/feather/feather.css";
import "./index.css";

import configureStore, { history } from "./store";
import { Loading } from "./Loading/Loading";
import { QueryParamProvider } from "use-query-params";
import { Route } from "react-router-dom";

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <QueryParamProvider ReactRouterRoute={Route}>
        <Loading />
      </QueryParamProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
