import { map, switchMap, tap } from "rxjs/operators";
import { from } from "rxjs";
import { ofType } from "redux-observable";
import API from "../api";
import { fetchContainerSize } from "../ContainerSizeDetails/effects";

export const EDIT_CONTAINER_SIZE = "EDIT_CONTAINER_SIZE";
export const EDIT_CONTAINER_SIZE_SUCCESSFUL = "EDIT_CONTAINER_SIZE_SUCCESSFUL";

const updateContainerSize = ({ id, containerSize }) =>
  API.post(`container-sizes/${id}`, { json: containerSize }).then(() =>
    editContainerSizeSuccessful()
  );

export const editContainerSize = (id, containerSize) => ({
  type: EDIT_CONTAINER_SIZE,
  payload: {
    id,
    containerSize,
  },
});

export const editContainerSizeSuccessful = () => ({
  type: EDIT_CONTAINER_SIZE_SUCCESSFUL,
});

export const editContainerSizeEpic = (action$) =>
  action$.pipe(
    ofType(EDIT_CONTAINER_SIZE),
    switchMap((action: any) => from(updateContainerSize(action.payload)))
  );

export const refreshContainerSizeEpic = (action$, $state) =>
  action$.pipe(
    ofType(EDIT_CONTAINER_SIZE_SUCCESSFUL),
    map((action) => fetchContainerSize($state.value.currentContainerSize.id))
  );
