import * as React from "react";
import {
  Modal,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Col,
  Input,
  Badge,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { editContainer } from "./effects";
import { fetchLocations } from "../LocationList/effects";
import { fetchDevices } from "../Devices/effects";
import { fetchChemicals } from "../ChemicalList/effects";
import { fetchContainerSizes } from "../ContainerSizeList/effects";

export const ContainerEdit = (props) => {
  const [name, setName] = React.useState("");
  const [containerSize, setContainerSize] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [chemical, setChemical] = React.useState("");
  const [device, setDevice] = React.useState("");

  const devices: any = useSelector<any>((state) => state.devices);
  const locations: any = useSelector<any>((state) => state.locations);
  const chemicals: any = useSelector<any>((state) => state.chemicals);
  const containerSizes: any = useSelector<any>((state) => state.containerSizes);
  const container: any = useSelector<any>((state) => state.currentContainer);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      fetchLocations({
        limit: 1000,
      })
    );
    dispatch(fetchDevices());
    dispatch(
      fetchChemicals({
        limit: 1000,
      })
    );
    dispatch(fetchContainerSizes());
  }, []);

  React.useEffect(() => {
    setName(container.name);
    setContainerSize(container.containerSizeId);
    setLocation(container.locationId);
    setChemical(container.chemicalId);
    setDevice(container.deviceId);
  }, [container]);

  const updateContainer = React.useCallback(() => {
    props.toggle();
    return dispatch(
      editContainer(container.id, {
        name: name,
        containerSizeId: containerSize,
        locationId: location,
        chemicalId: chemical,
        deviceId: device,
      })
    );
  }, [dispatch, name, containerSize, location, chemical, device, container]);

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle}>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          updateContainer();
        }}
      >
        <Card className="modal-card">
          <CardHeader>
            <h4 className="card-header-title">Add Container</h4>
            <Button close className="float-right" onClick={props.toggle} />
          </CardHeader>
          <CardBody>
            <FormGroup row>
              <Label for="exampleSelect" sm={2}>
                Name
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="location" sm={2}>
                Location
              </Label>
              <Col sm={10}>
                <Input
                  type="select"
                  name="location"
                  id="location"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                >
                  <option>Select Location</option>
                  {locations.rows.map((location) => (
                    <option key={location.id} value={location.id}>
                      {location.name}
                    </option>
                  ))}
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="containerSize" sm={2}>
                Container Size
              </Label>
              <Col sm={10}>
                <Input
                  type="select"
                  name="containerSize"
                  id="containerSize"
                  value={containerSize}
                  onChange={(e) => setContainerSize(e.target.value)}
                >
                  <option>Select Size</option>
                  {containerSizes.rows.map((containerSize) => (
                    <option key={containerSize.id} value={containerSize.id}>
                      {containerSize.name}
                    </option>
                  ))}
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="chemical" sm={2}>
                Chemical
              </Label>
              <Col sm={10}>
                <Input
                  type="select"
                  name="chemical"
                  id="chemical"
                  value={chemical}
                  onChange={(e) => setChemical(e.target.value)}
                >
                  <option>Select Chemical</option>
                  {chemicals.rows.map((chemical) => (
                    <option key={chemical.id} value={chemical.id}>
                      {chemical.name}
                    </option>
                  ))}
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="device" sm={2}>
                Device
              </Label>
              <Col sm={10}>
                <Input
                  type="select"
                  name="device"
                  id="device"
                  value={device}
                  onChange={(e) => setDevice(e.target.value)}
                >
                  <option>Assign Device</option>
                  {devices.rows.map((device) => (
                    <option key={device.id} value={device.id}>
                      {device.id}
                    </option>
                  ))}
                </Input>
              </Col>
            </FormGroup>
          </CardBody>
          <CardFooter>
            <Button
              color="success"
              className="float-right ml-2"
              onClick={updateContainer}
            >
              Update
            </Button>
            <Button outline className="float-right" onClick={props.toggle}>
              Cancel
            </Button>
          </CardFooter>
        </Card>
      </Form>
    </Modal>
  );
};
