import { map, switchMap, tap } from "rxjs/operators";
import { from } from "rxjs";
import { ofType } from "redux-observable";
import API from "../api";
import { fetchCustomer } from "../CustomerDetails/effects";

export const EDIT_CUSTOMER = "EDIT_CUSTOMER";
export const EDIT_CUSTOMER_SUCCESSFUL = "EDIT_CUSTOMER_SUCCESSFUL";

const updateCustomer = ({ id, customer }) =>
  API.post(`customers/${id}`, { json: customer }).then(() =>
    editCustomerSuccessful()
  );

export const editCustomer = (id, customer) => ({
  type: EDIT_CUSTOMER,
  payload: {
    id,
    customer,
  },
});

export const editCustomerSuccessful = () => ({
  type: EDIT_CUSTOMER_SUCCESSFUL,
});

export const editCustomerEpic = (action$) =>
  action$.pipe(
    ofType(EDIT_CUSTOMER),
    switchMap((action: any) => from(updateCustomer(action.payload)))
  );

export const refreshCustomerEpic = (action$, $state) =>
  action$.pipe(
    ofType(EDIT_CUSTOMER_SUCCESSFUL),
    map((action) => fetchCustomer($state.value.currentCustomer.id))
  );
