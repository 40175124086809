import { map, switchMap, tap } from "rxjs/operators";
import { from } from "rxjs";
import { ofType } from "redux-observable";
import { push } from "connected-react-router";
import API from "../api";

export const FETCH_CONTAINER_SIZES = "FETCH_CONTAINER_SIZES";
export const FETCH_CONTAINER_SIZES_SUCCESSFUL =
  "FETCH_CONTAINER_SIZES_SUCCESSFUL";

const getContainerSizes = () =>
  API.get("container-sizes")
    .json<any>()
    .then((containerSizes = []) =>
      fetchContainerSizesSuccessful(containerSizes)
    );

export const fetchContainerSizes = () => ({
  type: FETCH_CONTAINER_SIZES,
});

export const fetchContainerSizesSuccessful = (containerSizes: any) => ({
  type: FETCH_CONTAINER_SIZES_SUCCESSFUL,
  payload: containerSizes,
});

export const fetchContainerSizesEpic = (action$) =>
  action$.pipe(
    ofType(FETCH_CONTAINER_SIZES),
    switchMap((action: any) => from(getContainerSizes()))
  );

export const containerSizesReducer = (
  state = { rows: [], count: 0, loading: true },
  action: any
) => {
  switch (action.type) {
    case FETCH_CONTAINER_SIZES:
      return {
        ...state,
        rows: [],
        count: 0,
        loading: true,
      };
    case FETCH_CONTAINER_SIZES_SUCCESSFUL:
      return {
        ...action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
