import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import React from "react";

export const BasePagination = ({ setPage, page, maxPages }) =>
  maxPages > 1 && (
    <Pagination
      size="lg"
      aria-label="Page navigation example"
      className="m-4 mt-3"
    >
      <PaginationItem>
        <PaginationLink first onClick={() => setPage(1)} />
      </PaginationItem>
      {page - 1 > 0 ? (
        <PaginationItem>
          <PaginationLink previous onClick={() => setPage(page - 1)} />
        </PaginationItem>
      ) : null}
      {page - 2 > 0 ? (
        <PaginationItem>
          <PaginationLink onClick={() => setPage(page - 2)}>
            {page - 2}
          </PaginationLink>
        </PaginationItem>
      ) : null}
      {page - 1 > 0 ? (
        <PaginationItem>
          <PaginationLink onClick={() => setPage(page - 1)}>
            {page - 1}
          </PaginationLink>
        </PaginationItem>
      ) : null}
      <PaginationItem>
        <PaginationLink>{page}</PaginationLink>
      </PaginationItem>
      {page + 1 <= maxPages ? (
        <PaginationItem>
          <PaginationLink onClick={() => setPage(page + 1)}>
            {page + 1}
          </PaginationLink>
        </PaginationItem>
      ) : null}
      {page + 2 <= maxPages ? (
        <PaginationItem>
          <PaginationLink onClick={() => setPage(page + 2)}>
            {page + 2}
          </PaginationLink>
        </PaginationItem>
      ) : null}
      {page + 1 <= maxPages ? (
        <PaginationItem>
          <PaginationLink next onClick={() => setPage(page + 1)} />
        </PaginationItem>
      ) : null}
      <PaginationItem>
        <PaginationLink last onClick={() => setPage(maxPages)} />
      </PaginationItem>
    </Pagination>
  );
