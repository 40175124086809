import { map, switchMap, tap } from "rxjs/operators";
import { from } from "rxjs";
import { ofType } from "redux-observable";
import { push } from "connected-react-router";
import API from "../api";

export const FETCH_USERS = "FETCH_USERS";
export const FETCH_USERS_SUCCESSFUL = "FETCH_USERS_SUCCESSFUL";

const getUsers = (options) =>
  API.get("users", {
    searchParams: options,
  })
    .json<any>()
    .then((users = []) => fetchUsersSuccessful(users));

export const fetchUsers = (options) => ({
  type: FETCH_USERS,
  payload: options,
});

export const fetchUsersSuccessful = (users: any) => ({
  type: FETCH_USERS_SUCCESSFUL,
  payload: users,
});

export const fetchUsersEpic = (action$) =>
  action$.pipe(
    ofType(FETCH_USERS),
    switchMap((action: any) => from(getUsers(action.payload)))
  );

export const usersReducer = (
  state = { rows: [], count: 0, loading: true },
  action: any
) => {
  switch (action.type) {
    case FETCH_USERS:
      return {
        ...state,
        rows: [],
        count: 0,
        loading: true,
      };
    case FETCH_USERS_SUCCESSFUL:
      return {
        ...action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
