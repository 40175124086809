import * as React from "react";
import { DefaultLayout } from "../_components/DefaultLayout";
import { Header } from "../_components/Header";
import { Spinner } from "reactstrap";
import { AverageCostPerWash } from "../Graphs/AverageCostPerWash";
import { TotalWashes } from "../Graphs/TotalWashes";
import { AddonConversionRate } from "../Graphs/AddonConversionRate";
import { ChemicalChangeOuts } from "../Graphs/ChemicalSwaps";
import { ChemicalNeededByLocation } from "../Graphs/ChemicalNeededByLocation";
import { CostPerChemical } from "../Graphs/CostPerChemical";

export const Dashboard = () => (
  <DefaultLayout>
    <div className="col-12">
      <Header title="Dashboard" pretitle="Overview">
        <ul className="nav nav-tabs header-tabs">
          <li className="nav-item">
            <a href="#" className="nav-link text-center active">
              Weekly
            </a>
          </li>
          <li className="nav-item">
            <a href="#" className="nav-link text-center">
              Monthly
            </a>
          </li>
          <li className="nav-item">
            <a href="#" className="nav-link text-center">
              Yearly
            </a>
          </li>
        </ul>
      </Header>
      <div className="row">
        <div className="col-3">
          <AddonConversionRate rate={5.7} />
        </div>
        <div className="col-3">
          <AverageCostPerWash cost={1.18} />
        </div>
        <div className="col-3">
          <TotalWashes total={437} />
        </div>
        <div className="col-3">
          <ChemicalChangeOuts total={12} />
        </div>
        <div className="col-7">
          <ChemicalNeededByLocation />
        </div>
        <div className="col-5">
          <CostPerChemical />
        </div>
      </div>
    </div>
  </DefaultLayout>
);
