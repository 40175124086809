import { map, switchMap, tap } from "rxjs/operators";
import { from } from "rxjs";
import { ofType } from "redux-observable";
import API from "../api";

export const FETCH_CONTAINER_SIZE = "FETCH_CONTAINER_SIZE";
export const FETCH_CONTAINER_SIZE_SUCCESSFUL =
  "FETCH_CONTAINER_SIZE_SUCCESSFUL";
export const FETCH_CONTAINER_SIZE_CONTAINERS =
  "FETCH_CONTAINER_SIZE_CONTAINERS";
export const FETCH_CONTAINER_SIZE_CONTAINERS_SUCCESSFUL =
  "FETCH_CONTAINER_SIZE_CONTAINERS_SUCCESSFUL";

const getContainerSize = (id) =>
  API.get(`container-sizes/${id}`)
    .json<any>()
    .then((containerSize = {}) => fetchContainerSizeSuccessful(containerSize));

const getContainerSizeContainers = (id) =>
  API.get(`container-sizes/${id}/containers`)
    .json<any>()
    .then((containers = {}) =>
      fetchContainerSizeContainersSuccessful(containers)
    );

export const fetchContainerSize = (id) => ({
  type: FETCH_CONTAINER_SIZE,
  payload: id,
});

export const fetchContainerSizeSuccessful = (containerSize: any) => ({
  type: FETCH_CONTAINER_SIZE_SUCCESSFUL,
  payload: containerSize,
});

export const fetchContainerSizeContainers = (id) => ({
  type: FETCH_CONTAINER_SIZE_CONTAINERS,
  payload: id,
});

export const fetchContainerSizeContainersSuccessful = (containerSize: any) => ({
  type: FETCH_CONTAINER_SIZE_CONTAINERS_SUCCESSFUL,
  payload: containerSize,
});

export const fetchContainerSizeEpic = (action$) =>
  action$.pipe(
    ofType(FETCH_CONTAINER_SIZE),
    switchMap((action: any) => from(getContainerSize(action.payload)))
  );

export const fetchContainerSizeContainersEpic = (action$) =>
  action$.pipe(
    ofType(FETCH_CONTAINER_SIZE_CONTAINERS),
    switchMap((action: any) => from(getContainerSizeContainers(action.payload)))
  );

export const containerSizeReducer = (state = {}, action: any) => {
  switch (action.type) {
    case FETCH_CONTAINER_SIZE_SUCCESSFUL:
      return action.payload;
    default:
      return state;
  }
};

export const containerSizeContainersReducer = (
  state = { rows: [] },
  action: any
) => {
  switch (action.type) {
    case FETCH_CONTAINER_SIZE_CONTAINERS_SUCCESSFUL:
      return action.payload;
    default:
      return state;
  }
};
